import { FC } from 'react'
import RealtimeSite from './RealtimePage'
import ObjectRealtime from './ObjectRealtime'
import { Routes, Route } from 'react-router-dom'
import RealtimeLayout from './layout'

const SiteDashboardRoutes: FC = () => {
    return (
        <Routes>
            <Route element={<RealtimeLayout />} path="/">
                <Route path="/" element={<RealtimeSite />} />
                <Route path=":objectId/*" element={<ObjectRealtime />} />
            </Route>
        </Routes>
    )
}
export default SiteDashboardRoutes
