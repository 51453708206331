import { FC } from 'react'
import { useTimeSeriesMeasurements } from '../../timeSeries/useTimeSeriesMeasurements'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'
import sumbBy from 'lodash/sumBy'
import { MeterType, Measurement } from '@/types/meters'
import { LoadingSkeletons } from './LoadingSkeleton'
import { useSite } from '@/views/site/useSite'
import min from 'lodash/min'
import max from 'lodash/max'

const format = (n: number, decimals = 2) =>
    new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: decimals,
    }).format(n)

const getTimeSpanDays = (measurements: Measurement[]) => {
    if (!measurements.length) {
        return 0
    }
    const timestamps = measurements.map((m) => new Date(m.timestamp).getTime())
    const minTimestamp = min(timestamps) as number
    const maxTimestamp = max(timestamps) as number
    return (maxTimestamp - minTimestamp) / (1000 * 60 * 60 * 24)
}

const EnergySummary: FC = () => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.main' })
    const { site } = useSite()
    const { series } = useTimeSeriesMeasurements({ type: MeterType.ENERGY, series: ['energy'] })
    const isLoading = series.some((s) => s.isLoading)
    if (isLoading) {
        return <LoadingSkeletons count={3} sx={{ py: 1 }} />
    }
    const allMeasurements = series.flatMap((s) => s.data.measurements)
    const total = sumbBy(allMeasurements, 'value')
    const timeSpanDays = getTimeSpanDays(allMeasurements)
    const perSurface = total / site.surface
    const perDay = timeSpanDays > 0 ? format(total / timeSpanDays) : '-'
    const unit = series[0]?.data.unit || 'kWh'
    const surfaceUnit = 'm²'
    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>{t('energy.avgPerDay')}</TableCell>
                    <TableCell>
                        {perDay}&thinsp;{unit}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t('energy.perSurface')}</TableCell>
                    <TableCell>
                        {format(perSurface)}&thinsp;{unit}/{surfaceUnit}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t('energy.total')}</TableCell>
                    <TableCell>
                        {format(total, 0)}&thinsp;{unit}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default EnergySummary
