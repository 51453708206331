import { useTranslation } from 'react-i18next'
import ChartContainer from '../../components/timeSeries/ChartContainer'
import DashboardSection from '../DashboardSection'
import EnergySummary from './EnergySummary'
import HVACSummary from './HVACSummary'
import MeteoSummary from './MeteoSummary'
import DualAxesChart from '../../components/timeSeries/customCharts/DualAxesChart'
import { MeterType } from '@/types/meters'

const MainDashboard = () => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.main' })

    return (
        <DashboardSection>
            <ChartContainer title={t('energy.title')} size="1/2">
                <EnergySummary />
            </ChartContainer>
            <ChartContainer title={t('hvac.title')} size="1/2">
                <HVACSummary />
            </ChartContainer>
            <ChartContainer title={t('thermosensitivity.title')} size="1">
                <DualAxesChart
                    primaryAxis={{
                        filters: {
                            type: MeterType.ENERGY,
                            series: ['energy'],
                        },
                        parsingOptions: {},
                        aggregateOptions: {
                            groupBy: 'day',
                            aggBy: ['sum'],
                        },
                        type: 'bar',
                        title: t('thermosensitivity.consumption'),
                    }}
                    secondaryAxis={{
                        filters: {
                            type: MeterType.METEO,
                            series: ['temperature_2m'],
                        },
                        parsingOptions: {
                            labelGetter: () => t('thermosensitivity.temperature'),
                        },
                        aggregateOptions: {
                            groupBy: 'day',
                            aggBy: ['avg'],
                        },
                        type: 'spline',
                        title: t('thermosensitivity.temperature'),
                    }}
                    options={{}}
                />
            </ChartContainer>
            <ChartContainer title={t('meteo.title')} size="1/2">
                <MeteoSummary />
            </ChartContainer>
        </DashboardSection>
    )
}

export default MainDashboard
