import EmptyState from './EmptyState'
import { useTranslation } from 'react-i18next'
import UnderConstruction from '@/assets/img/illustrations/undraw_under-construction.svg'

const OccupancyDashboard = () => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.occupancy' })
    return <EmptyState title={t('title')} description={t('description')} imgSrc={UnderConstruction} />
}

export default OccupancyDashboard
