import { FC } from 'react'
import { useTimeSeriesMeasurements } from '../../timeSeries/useTimeSeriesMeasurements'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MeterType } from '@/types/meters'
import { LoadingSkeleton } from './LoadingSkeleton'
import { formatValue, getValue, getIsLoading, getUnit } from './seriesUtils'

const MeteoSummary: FC = () => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard' })
    const { series: temperatureSeries } = useTimeSeriesMeasurements({
        type: MeterType.METEO,
        series: ['temperature_2m'],
    })
    const { series: precipitationsSeries } = useTimeSeriesMeasurements({
        type: MeterType.METEO,
        series: ['precipitation'],
    })
    const { series: sunshineDurationSeries } = useTimeSeriesMeasurements(
        {
            type: MeterType.METEO,
            series: ['sunshine_duration'],
        },
        {},
        { unit: 'hours' }
    )

    const avgMetrics = [
        {
            key: 'temperature',
            series: temperatureSeries,
            formatter: formatValue,
            aggregationFn: 'mean',
        },
        {
            key: 'precipitation',
            series: precipitationsSeries,
            formatter: formatValue,
            aggregationFn: 'sum',
        },
        {
            key: 'sunshineDuration',
            series: sunshineDurationSeries,
            formatter: formatValue,
            aggregationFn: 'sum',
        },
    ]

    return (
        <Table>
            <TableBody>
                {avgMetrics.map(({ key, series, formatter, aggregationFn }) => {
                    const unit = getUnit(series)
                    const formattedValue = formatter(getValue(series, aggregationFn as 'mean' | 'sum'))
                    return (
                        <TableRow key={key}>
                            <TableCell>{t(`meteo.${key}`, { defaultValue: key })}</TableCell>
                            <TableCell>
                                {getIsLoading(series) ? (
                                    <LoadingSkeleton sx={{ mr: 6 }} />
                                ) : (
                                    <span>
                                        {formattedValue}&nbsp;{unit}
                                    </span>
                                )}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}

export default MeteoSummary
