import { FC } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { SiteContextProvider, useSite } from './useSite'
import { Container, Box, Divider, Typography } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import SiteFallback from './fallback.component'
import { createPortal } from 'react-dom'
import SiteSwitch from './SiteSwitch'
import { matchSiteRoute, getAllowedSiteRoutes } from './siteRoutes'
import useAuth from '@/context/useAuth'
import { ForbiddenError } from '@/utils/error.utils'

const SiteLayout: FC = () => {
    const { site } = useSite()
    const headerTitleContainer = document.getElementById('header-title-container')
    const { me } = useAuth()
    const { pathname } = useLocation()
    if (!me) {
        return null
    }
    const allowedRoutes = getAllowedSiteRoutes(me.userPermissions, site.hash)
    const matchedRoute = matchSiteRoute(pathname)
    if (matchedRoute && !allowedRoutes.includes(matchedRoute)) {
        throw new ForbiddenError('User does not have permission to access this page')
    }

    return (
        <>
            {headerTitleContainer &&
                createPortal(
                    <Box
                        sx={{
                            display: 'flex',
                            gap: { xs: 1, sm: 2 },
                            ml: { xs: 0, sm: 1 },
                            alignItems: 'center',
                        }}
                    >
                        <Divider orientation="vertical" flexItem sx={{ borderColor: 'grey.200', my: 1 }} />
                        <Typography variant="h6" component="h1" sx={{ color: 'white' }}>
                            {site.name}
                        </Typography>
                        <SiteSwitch />
                    </Box>,
                    headerTitleContainer
                )}

            <Container>
                <Outlet />
            </Container>
        </>
    )
}

const SiteLayoutWrapper: FC = () => {
    return (
        <ErrorBoundary fallbackRender={SiteFallback}>
            <SiteContextProvider>
                <SiteLayout />
            </SiteContextProvider>
        </ErrorBoundary>
    )
}
export default SiteLayoutWrapper
