import React, { useEffect, useRef, useState } from 'react'
import Highcharts from 'highcharts/highmaps'
import HighchartsReact from 'highcharts-react-official'
import mapDataWorld from './euro.geo.json'
import { Card, CardContent, Skeleton } from '@mui/material'

export const MapChart = ({ aggregatedSitesPerPerimeter, perimeters }) => {
    const chart = useRef()
    const [options, setOptions] = useState(null)

    useEffect(() => {
        const processData = (dataset) => {
            let _data = {}
            dataset?.forEach((item, index) => {
                if (item?.countryCode === '-') {
                    // use countryCodes that is like that {FR: 13, ES: 3, DE: 1, PT: 1, NL: 3}
                    let _countryCodes = item?.countryCodes
                    Object.keys(_countryCodes).forEach((code) => {
                        if (_data[code]) {
                            _data[code].value += _countryCodes[code]
                            _data[code].perimeters.push(item?.perimeterIndex)
                        } else {
                            _data[code] = {
                                value: _countryCodes[code],
                                perimeters: [item?.perimeterIndex],
                            }
                        }
                    })
                } else {
                    if (_data[item.countryCode]) {
                        _data[item.countryCode].value += 1
                        _data[item.countryCode].perimeters.push(item?.perimeterIndex)
                    } else {
                        _data[item.countryCode] = {
                            value: 1,
                            perimeters: [item?.perimeterIndex],
                        }
                    }
                }
            })
            return Object.entries(_data).map(([code, details]) => ({
                code,
                value: details.value,
                name: code,
                perimeters: details.perimeters,
            }))
        }

        let chartData = []
        chartData.push(processData(aggregatedSitesPerPerimeter))

        let _mapData = { ...mapDataWorld }
        _mapData.features = _mapData.features.filter((el) => chartData.flat().some((d) => d.code === el.id))

        setOptions({
            chart: {
                map: _mapData,
            },
            title: {
                text: 'Répartition des actifs',
            },
            mapNavigation: {
                enabled: true,
                enableMouseWheelZoom: false,
            },
            colorAxis: {
                min: 0,
            },
            series: chartData.map((data, index) => {
                return {
                    name: perimeters?.length > 1 ? index + 1 : 'Hotels',
                    mapData: _mapData,
                    data: data,
                    joinBy: ['iso-a2', 'code'],
                    states: {
                        hover: {
                            color: perimeters?.[index]?.perimeter?.color,
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}',
                    },
                    tooltip: {
                        headerFormat: '',
                        pointFormatter: function () {
                            let out = ''
                            let firstSiteCode = aggregatedSitesPerPerimeter?.[0]?.countryCode
                            if (firstSiteCode === '-') {
                                let filteredBrands = aggregatedSitesPerPerimeter?.filter((el) =>
                                    // eslint-disable-next-line no-prototype-builtins
                                    el?.countryCodes?.hasOwnProperty(this.code)
                                )
                                filteredBrands?.forEach((brand) => {
                                    out += brand?.name + ' : ' + brand?.countryCodes[this.code] + '<br/>'
                                })
                            } else {
                                perimeters?.forEach((perimeter, i) => {
                                    let color = perimeter?.perimeter?.color
                                    out +=
                                        '<span style="color:' +
                                        color +
                                        // Colorized bullet
                                        '">\u25CF</span> ' +
                                        '<div style="background-color:' +
                                        color +
                                        '; padding:2px; border-radius:4px; width: 10px; height: 10px; ">' +
                                        (i + 1) +
                                        '</div> ' +
                                        ': ' +
                                        this.perimeters?.filter((el) => el === i).length +
                                        '<br/>'
                                })
                            }

                            return out
                        },
                    },
                }
            }),
        })
    }, [aggregatedSitesPerPerimeter])

    if (!options) return <Skeleton />

    return (
        <Card style={{ height: '100%' }}>
            <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'mapChart'}
                    options={options}
                    ref={chart}
                    immutable={true}
                />
            </CardContent>
        </Card>
    )
}
