import AccountCircle from '@mui/icons-material/AccountCircle'
import LockIcon from '@mui/icons-material/Lock'
import SecurityIcon from '@mui/icons-material/Security'
import { Snackbar, Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import hash from 'object-hash'
import React, { useEffect, useState } from 'react'
import { deviceDetect } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'
import uuid from 'react-uuid'
import Logo from '../../assets/img/brand/logo.png'
import { textBlue } from '../../components/Basic/colors'
import { resetPassword, signin } from '../../services/auth.service'
import useLocale from '../../utils/locale/locale.hook'
import { validateEmail } from '../../utils/regex.utils'
import CodeComponent from './components/code.component'

const Login = ({ pathname }) => {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [errors, setErrors] = useState([])
    const [fingerPrint, setFingerPrint] = useState(null)
    const [showCode, setShowCode] = useState(false)
    const [code, setCode] = useState(null)
    const [disabled, setDisabled] = useState(true)
    const [forgotPassword, setForgotPassword] = useState(false)
    const [open, setOpen] = useState(false)
    const [captcha, setCaptach] = useState(null)
    const [captchaValid, setCaptchaValid] = useState(false)
    let locale = useLocale()
    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    let query = useQuery()
    useEffect(() => {
        console.log("login")
        logAs()
        loadCaptchaEnginge(6)
        let key = uuid()
        let device_cookie = localStorage.getItem('device_cookie')
        if (!device_cookie) {
            localStorage.setItem('device_cookie', key)
            device_cookie = key
        }
        let data = deviceDetect()
        data = { ...data, device_cookie }

        var h = hash({ ...data })
        setFingerPrint(h)
    }, [])

    const logAs = async () => {
        let _logAsToken = query.get('logAs')
        if (_logAsToken) {
            let res = await signin({ logAs: _logAsToken })
            if (res && res.jwt) {
                localStorage.setItem('token', res.jwt)
                if (pathname) window.location.href = pathname
                else window.location.href = '/'
            } else {
                // refresh page
                window.location.href = '/login'
            }
        }
    }

    const verifyInputs = () => {
        let errors = []
        if (!validateEmail(email)) errors.push('email')
        if (!password) errors.push('password')
        setErrors(errors)
        return errors.length > 0
    }

    const submit = async () => {
        if (!captchaValid) {
            let valid = validateCaptcha(captcha) == true
            if (valid) setCaptchaValid(true)
            else setCaptach('')
            return
        }
        let hasErrors = verifyInputs()
        if (forgotPassword) {
            setLoading(true)
            let res = await resetPassword({ email })
            if (res?.status) {
                setOpen(true)
                setForgotPassword(false)
            } else {
                setError('Une erreur est survenue')
            }
            setLoading(false)
            return
        }
        if (!hasErrors) {
            setLoading(true)
            setError('')
            console.log({ email, password, fingerPrint, code })
            let res = await signin({ email, password, fingerPrint, code })
            localStorage.removeItem('isTest')
            if (res && res.jwt) {
                if (email === 'test@test.com') {
                    localStorage.setItem('isTest', true)
                }
                localStorage.setItem('token', res.jwt)
                if (pathname) window.location.href = pathname
                else window.location.href = '/'
            } else {
                if (res?.toggleCode) {
                    setError(
                        'New device. An email has been sent to the e-mail address. The code will be valid for 20 minutes.'
                    )
                    setShowCode(true)
                } else {
                    setError('An error occured. Be sure that you username/password is correct.')
                }
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        let hasErrors = verifyInputs()
        if (!hasErrors) {
            setDisabled(false)
        } else if (forgotPassword && validateEmail(email)) {
            setDisabled(false)
        } else setDisabled(true)
    }, [email, password, forgotPassword])

    const isDisabled = () => {
        let out = !captchaValid && captcha && captcha?.length == 6 ? false : disabled || loading
        return out
    }

    const checkKeyPress = (e) => {
        const { key, keyCode } = e

        if (keyCode === 13) {
            let _disabled = !captchaValid && captcha && captcha?.length == 6 ? false : disabled || loading
            if (!_disabled) submit()
        }
    }

    return (
        <Container
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
            }}
        >
            {loading && (
                <Backdrop open={true} style={{ color: '#fff', zIndex: 1000 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <img
                src={Logo}
                alt="agrid_log"
                style={{
                    width: 120,
                    objectFit: 'contain',
                }}
            />
            <div
                style={{
                    color: textBlue,
                    width: '274px',
                    textAlign: 'center',
                    marginBottom: 50,
                    marginTop: 15,
                    fontSize: 16,
                }}
            >
                Be present, think about your future
            </div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                message="Mot de passe envoyé"
            />
            <Card style={{ padding: 30, width: 350, borderRadius: 16 }}>
                <CardContent>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {!showCode && captchaValid && (
                            <>
                                <TextField
                                    id="email-with-icon-textfield"
                                    label="Email"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder="mail@gmail.com"
                                    variant="standard"
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{ margin: 5 }}
                                />
                                {!forgotPassword && (
                                    <TextField
                                        id="password-with-icon-textfield"
                                        label={locale?.['auth']?.['passwordTxt']}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        type="password"
                                        placeholder="* * * * * *"
                                        onChange={(e) => setPassword(e.target.value)}
                                        style={{ margin: 5 }}
                                        onKeyDown={checkKeyPress}
                                    />
                                )}
                            </>
                        )}
                        {showCode && (
                            <CodeComponent submit={(val) => setCode(val)} label={locale?.['auth']?.['code']} />
                            // <TextField
                            //   id="input-with-icon-textfield"
                            //   label={locale?.["auth"]?.["code"]}
                            //   label="Code (4 chiffres)"
                            //   variant="standard"
                            //   InputProps={{
                            //     startAdornment: (
                            //       <InputAdornment position="start">
                            //         <MarkEmailReadIcon />
                            //       </InputAdornment>
                            //     ),
                            //   }}
                            //   placeholder="X X X X"
                            //   type="number"
                            //   onChange={(e) => {
                            //     setCode(e.target.value);
                            //   }}
                            //   style={{ margin: 5 }}
                            // />
                        )}

                        {!captchaValid && (
                            <div>
                                <div
                                    style={{
                                        //border: "1px solid #e0e0e0",
                                        marginBottom: 10,
                                        diplay: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LoadCanvasTemplateNoReload />
                                </div>
                                <TextField
                                    id="captcha-textfield"
                                    defaultValue=""
                                    //label="Saisir les caractères ci-dessus"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SecurityIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={captcha}
                                    variant="outlined"
                                    type="text"
                                    placeholder={locale?.['auth']?.['fill']}
                                    onChange={(e) => setCaptach(e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        )}
                    </div>

                    <div className="text-center">
                        <Button
                            variant="contained"
                            onClick={submit}
                            style={{
                                cursor: 'pointer',
                                marginTop: 20,
                                width: '100%',
                            }}
                            disabled={!captchaValid && captcha && captcha?.length == 6 ? false : disabled || loading}
                        >
                            {showCode
                                ? locale?.['auth']?.['validate']
                                : forgotPassword
                                  ? locale?.['auth']?.['reinit']
                                  : !captchaValid
                                    ? locale?.['auth']?.['validate']
                                    : locale?.['auth']?.['connexion']}
                        </Button>
                        {forgotPassword && captchaValid && (
                            <Typography sx={{ fontSize: 10, marginTop: 2 }} color="text.secondary" gutterBottom>
                                Vous recevrez un code par mail. L'opération peut prendre quelques minutes.
                            </Typography>
                        )}
                    </div>
                    {error && <p style={{ fontSize: 12, color: 'red' }}>{error}</p>}
                    {captchaValid && (
                        <div
                            style={{
                                marginTop: 20,
                                fontSize: 11,
                                color: 'rgb(66, 133, 244)',
                                cursor: 'pointer',
                            }}
                            onClick={() => setForgotPassword(!forgotPassword)}
                        >
                            {!forgotPassword ? locale?.['auth']?.['forgot'] : locale?.['back']}
                        </div>
                    )}
                </CardContent>
            </Card>
        </Container>
    )
}

export default Login
