import { Measurement } from '@/types/meters'
type ValueConverter = (value: number) => number
type MeasurementConverter = (measurement: Measurement) => Measurement

interface UnitConverter {
    fromUnit: string | null
    toUnit: string
    convert: ValueConverter
}

const secondsToHours: UnitConverter = {
    fromUnit: 'seconds',
    toUnit: 'hours',
    convert: (value) => value / 3600,
}

const wattToKilowatt: UnitConverter = {
    fromUnit: 'W',
    toUnit: 'kW',
    convert: (value) => value / 1000,
}

const noUnitToPercent: UnitConverter = {
    fromUnit: null,
    toUnit: '%',
    convert: (value) => value * 100,
}

const unitConverters = [secondsToHours, wattToKilowatt, noUnitToPercent]

const valueConverterToMeasurementConverter = (converter: ValueConverter): MeasurementConverter => {
    return (measurement) => {
        return Object.entries(measurement).reduce((acc, [key, value]) => {
            if (typeof value === 'number') {
                return { ...acc, [key]: converter(value) }
            }
            return { ...acc, [key]: value }
        }, {} as Measurement)
    }
}

export const getUnitConverterFunction = (fromUnit: string | null, toUnit: string): MeasurementConverter | undefined => {
    const converter = unitConverters.find((c) => c.fromUnit === fromUnit && c.toUnit === toUnit)
    if (!converter) {
        return undefined
    }
    return valueConverterToMeasurementConverter(converter.convert)
}
