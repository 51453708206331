import { ObjectRealtimeTabRoutes } from './objectRealtimeRoutes'
import { Tab, Tabs, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useMatch, Outlet } from 'react-router-dom'

const ObjectRealtimeLayout = () => {
    const { t } = useTranslation('site', { keyPrefix: 'realtime' })
    const match = useMatch(`/sites/:siteId/realtime/:objectId/${ObjectRealtimeTabRoutes.HISTORY}`)
    const tabValue = match ? ObjectRealtimeTabRoutes.HISTORY : ObjectRealtimeTabRoutes.TELECOMMAND
    return (
        <Box>
            <Tabs value={tabValue} sx={(theme) => ({ my: 2, borderBottom: `1px solid ${theme.palette.divider}` })}>
                <Tab
                    label={t('objectTabs.telecommand')}
                    value={ObjectRealtimeTabRoutes.TELECOMMAND}
                    component={Link}
                    to={ObjectRealtimeTabRoutes.TELECOMMAND}
                />
                <Tab
                    label={t('objectTabs.history')}
                    value={ObjectRealtimeTabRoutes.HISTORY}
                    component={Link}
                    to={ObjectRealtimeTabRoutes.HISTORY}
                />
            </Tabs>
            <Outlet />
        </Box>
    )
}

export default ObjectRealtimeLayout
