export const SCATTER_FILTER_PERCENTAGE = 20

export const parseScatterData = (data, t_m) =>
    Object.keys(t_m)
        ?.map((key) => {
            return {
                y: parseInt(parseFloat(t_m[key]?.TinMeanOn) * 10) / 10,
                x: parseInt(parseFloat(t_m[key]?.SetTempMean) * 10) / 10,
                name: t_m[key]?.ObjectName,
                id: t_m[key]?.ObjectHash,
                onPercentage: t_m[key]?.OnPercentage,
            }
        })
        ?.filter((el) => {
            return el?.onPercentage >= SCATTER_FILTER_PERCENTAGE / 100
        })
        .filter((el) => el?.x > 0 || el?.y > 0)
