import { FC } from 'react'
import { Skeleton, SkeletonProps, Box, BoxProps } from '@mui/material'

export const LoadingSkeleton: FC<SkeletonProps> = (props) => <Skeleton variant="rectangular" height={16} {...props} />

interface LoadingSkeletonProps extends BoxProps {
    count?: number
    height?: number
}

export const LoadingSkeletons: FC<LoadingSkeletonProps> = ({ count = 1, height = 24, ...boxProps }) => {
    return (
        <Box {...boxProps}>
            {Array.from({ length: count }).map((_, index) => (
                <Skeleton key={index} variant="rectangular" height={height} sx={{ mb: 2 }} />
            ))}
        </Box>
    )
}
