import { useParams } from 'react-router-dom'
import { DEFAULT_DASHBOARD_KEY, DashboardKeys } from './dashboardKeys'
import MainDashboard from './customDashboards/Main'
import EnergyDashboard from './customDashboards/Energy'
import MeteoDashboard from './customDashboards/Meteo'
import HVACDashboard from './customDashboards/HVAC'
import OccupancyDashboard from './customDashboards/Occupancy'
import FeedsDashboard from './customDashboards/Feeds'

const DashboardWrapper = () => {
    const { dashboardId = DEFAULT_DASHBOARD_KEY } = useParams()

    return (
        <>
            {dashboardId === DashboardKeys.MAIN && <MainDashboard />}
            {dashboardId === DashboardKeys.ENERGY && <EnergyDashboard />}
            {dashboardId === DashboardKeys.METEO && <MeteoDashboard />}
            {dashboardId === DashboardKeys.HVAC && <HVACDashboard />}
            {dashboardId === DashboardKeys.OCCUPANCY && <OccupancyDashboard />}
            {dashboardId === DashboardKeys.FEEDS && <FeedsDashboard />}
        </>
    )
}

export default DashboardWrapper
