/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react'
/** COMPONENTS */
import {
    AppBar,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
} from '@mui/material'
import { getObjectsForSite } from '../../services/realtime.service'
import Calendar from '../Sidebar/Calendar'
import NavSelect from './nav.select.component'

/** ICONS */
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import ArchitectureIcon from '@mui/icons-material/Architecture'
import DashboardIcon from '@mui/icons-material/Dashboard'
import HandymanIcon from '@mui/icons-material/Handyman'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import LiveTvIcon from '@mui/icons-material/LiveTv'
import LockIcon from '@mui/icons-material/Lock'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import Logo from '../../assets/img/brand/logo.png'
import { logout } from '../../services/auth.service'
import { store } from '../../store/configure.store'
import GlobalSlice from '../../store/slices/global.slice'
import { event } from '../../utils/ga.utils'
import useLocale from '../../utils/locale/locale.hook'
import { textBlue, textBlueLight } from '../Basic/colors'
import { NavCalendarComponent } from './nav-calendar.component'
import OnboardingComponent from './onboarding.component'
import { isDemo } from '../../services/utils.service'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { uniqueArray } from '@/utils/global.utils'

/** VARIABLES */
const drawerWidth = 240

function Layout(props) {
    const me = useSelector((state) => state.meStore?.me)
    const [onboardingDone, setOnboardingDone] = useState(true)

    const [onboardingStep, setOnboardingStep] = useState(0)

    const width = useSelector((state) => state.globalStore.width)
    const currentPath = useSelector((state) => state.globalStore.path)
    const { selectedSite, objects, selectedObject } = useSelector((state) => state.globalStore)
    const [gettingObjects, setGettingObjects] = useState(false)

    const [showSettings, setShowSettings] = useState(false)
    const navigate = useNavigate()
    const locale = useLocale()
    const pages = [
        {
            text: 'Dashboard',
            path: '/dashboard',
            admin: true,
            enabled: ['admin'],
            access: ['Editor', 'Viewer'],
            icon: () => {
                return <DashboardIcon color={currentPath?.includes('/dashboard') ? 'primary' : 'disabled'} />
            },
            accessKey: ['DashboardGroupView', 'DashboardSiteView'],
        },
        {
            text: 'Pilotage',
            path: '/realtime',
            enabled: ['user', 'admin'],
            access: ['Editor'],
            icon: () => {
                return <LiveTvIcon color={currentPath?.includes('/realtime') ? 'primary' : 'disabled'} />
            },
            accessKey: ['RealtimeView'],
        },
        {
            text: 'Configuration',
            path: '/constraints',
            admin: true,
            enabled: ['admin'],
            access: ['Editor'],
            icon: () => {
                return <ArchitectureIcon color={currentPath?.includes('constraints') ? 'primary' : 'disabled'} />
            },
            accessKey: ['ConstraintsView'],
        },
        {
            text: 'Maintenance',
            path: '/maintenance',
            dev: true,
            enabled: ['user', 'admin', 'worker'],
            access: [''],
            icon: () => {
                return <HandymanIcon color={currentPath?.includes('maintenance') ? 'primary' : 'disabled'} />
            },
            accessKey: ['MaintenanceView'],
        },
        {
            text: 'Mes documents',
            path: '/documents',
            enabled: ['admin', 'worker'],
            access: ['Editor', 'Viewer'],
            icon: () => {
                return <LibraryBooksIcon color={currentPath?.includes('/documents') ? 'primary' : 'disabled'} />
            },
            accessKey: ['DocumentsView'],
        },
        {
            text: 'Aide',
            path: '/help',
            enabled: ['admin', 'worker', 'user'],
            access: ['Editor', 'Viewer'],
            icon: () => {
                return <HelpOutlineIcon color={currentPath?.includes('/help') ? 'primary' : 'disabled'} />
            },
        },
        {
            text: 'Contact',
            path: '/help?form=true',
            enabled: ['admin', 'worker', 'user'],
            access: ['Editor', 'Viewer'],
            icon: () => {
                return <HelpOutlineIcon color={currentPath?.includes('/help') ? 'primary' : 'disabled'} />
            },
        },
        // {
        //     text: 'Support',
        //     path: '/support',
        //     enabled: ['admin', 'worker', 'user'],
        //     access: ['Editor', 'Viewer'],
        //     whitelist: [
        //         'admin@a-grid.com',
        //         'charles.moreau@a-grid.com',
        //         'valentin.malinge@a-grid.com',
        //         'ylan.brami@a-grid.com',
        //     ],
        //     icon: () => {
        //         return <SupportAgentIcon color={currentPath?.includes('/support') ? 'primary' : 'disabled'} />
        //     },
        // },
        {
            text: 'Mon espace',
            path: '/settings/security',
            enabled: ['admin', 'worker', 'user'],
            access: ['Editor', 'Viewer'],
            // disabledSite: true,
            icon: () => {
                return <LockIcon color={currentPath?.includes('/settings/security') ? 'primary' : 'disabled'} />
            },
        },
    ]
    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    let query = useQuery()

    // useEffect(() => {
    //   if (currentPath?.includes("/dashboard") && !me?.Onboarding) {
    //     setOnboardingDone(false);
    //   }
    // }, [me, currentPath]);
    useEffect(() => {
        let path = window.location.pathname
        if (path != currentPath) {
            store.dispatch(GlobalSlice.actions.setPath(path))
        }
        if (path.includes('settings')) setShowSettings(true)
    })
    const [mobileOpen, setMobileOpen] = React.useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    useEffect(() => {
        if (me) {
            fetchSites()
        }
    }, [me])

    // useEffect(() => {
    //   if (selectedSite && props.showObjects) getObjects();
    // }, [selectedSite, props.showObjects]);

    useEffect(() => {
        if (selectedSite && !gettingObjects) getObjects()
    }, [selectedSite])

    const getObjects = async () => {
        setGettingObjects(true)
        const res = await getObjectsForSite({ hash: selectedSite?.Hash })
        if (res.objects) {
            let _objects = uniqueArray(res.objects)
            store.dispatch(GlobalSlice.actions.setObjects(_objects))
            if (query.get('objectId')) {
                let find = _objects.find((el) => el.ObjectId == query.get('objectId'))
                if (find) store.dispatch(GlobalSlice.actions.setSelectedObject(find))
                else store.dispatch(GlobalSlice.actions.setSelectedObject(_objects?.[0]))
            }
        }
        setGettingObjects(false)
    }

    const fetchSites = async () => {
        // if (me?.sites?.length > 0) return;
        // let res = await getSites();
        let res = {
            sites: me?.sites,
        }
        if (res && res.sites && res.sites.length > 0) {
            store.dispatch(GlobalSlice.actions.setSites(res.sites))
            if (query.get('site')) {
                let find = res.sites.find((el) => el.Hash == query.get('site'))
                if (find) store.dispatch(GlobalSlice.actions.setSelectedSite(find))
                else store.dispatch(GlobalSlice.actions.setSelectedSite(res.sites[0]))
            } else {
                if (currentPath?.includes('/site') && selectedSite?.Hash != currentPath.split('/')[2]) {
                    let find = res?.sites?.find((el) => el.Hash === currentPath.split('/')[2])
                    store.dispatch(GlobalSlice.actions.setSelectedSite(find))
                } else store.dispatch(GlobalSlice.actions.setSelectedSite(res.sites[0]))
            }
        }
    }

    const changeSelectedSite = async (hash) => {
        let newSite = me?.sites.find((el) => el.Hash == hash)

        if (newSite) {
            store.dispatch(GlobalSlice.actions.setSelectedSite(newSite))
            if (currentPath.includes('/site')) {
                // history.push({
                //   pathname: `/site/${newSite.Hash}/${selectedBuilding.Hash}`,
                // });
                //window.location.reload();
            } else {
                navigate(`${currentPath}?site=${newSite.Hash}`)
            }
        }
    }

    const changeSelectedObject = async (id) => {
        let newObject = objects.find((el) => el.ObjectId === id)
        if (newObject) store.dispatch(GlobalSlice.actions.setSelectedObject(newObject))
    }

    const drawer = (
        <div style={{ border: 0 }}>
            <Toolbar>
                <div
                    style={{
                        background: 'white',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        src={Logo}
                        alt="agrid_log"
                        style={{
                            width: 120,
                            objectFit: 'contain',
                            // position: "fixed",
                            top: 20,
                            left: 20,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            navigate('/')
                        }}
                    />
                </div>
            </Toolbar>
            <Divider />
            <List>
                {pages
                    ?.filter((el) => {
                        if (el?.dev) return false
                        if (el?.whitelist) {
                            let find = el?.whitelist?.indexOf(me?.Email) > -1
                            if (!find) return false
                        }
                        let find = me?.userPermissions?.find((permission) => {
                            return el?.accessKey?.indexOf(permission?.Section) > -1
                        })

                        let demo = isDemo()
                        if (demo) return true
                        if (!el?.accessKey) return true

                        return find ? true : false
                        // let user_access = me?.Access;
                        // return el?.access?.indexOf(user_access) > -1;
                        // let type = me?.sites?.find((el) => el?.UserRole === "admin")
                        //   ? "admin"
                        //   : me?.sites?.find((el) => el?.UserRole === "worker")
                        //   ? "worker"
                        //   : "user";
                        // if (
                        //   (el.dev &&
                        //     !(
                        //       process.env.NODE_ENV === "development" ||
                        //       process.env.REACT_APP_ENV == "local"
                        //     )) ||
                        //   el?.enabled?.indexOf(type) === -1
                        // )
                        //   return false;
                        // return true;
                    })
                    .map((item, index) => {
                        return (
                            <ListItem
                                key={item.text}
                                onClick={() => {
                                    if (onboardingDone) {
                                        event('ChangePage', `${selectedSite?.Name} - ${item?.path}`)
                                        store.dispatch(GlobalSlice.actions.setSelectedObject(null))
                                        if (item?.disabledSite) {
                                            navigate(item.path)
                                        } else {
                                            navigate(`${item.path}?site=${selectedSite?.Hash}`)
                                        }
                                    }
                                }}
                                selected={currentPath?.includes(item.path)}
                                id={item.path}
                            >
                                <ListItemIcon>{item?.icon()}</ListItemIcon>
                                <ListItemText
                                    primary={item.text}
                                    style={{
                                        color: currentPath?.includes(item.path) ? textBlue : textBlueLight,
                                    }}
                                />
                            </ListItem>
                        )
                    })}
            </List>
            <Divider />
            {props.showCalendar && (
                <>
                    <div style={{ padding: 10 }}>
                        <h6 className="navbar-heading text-muted p-0">
                            <span className="docs-normal">{locale?.sidebar?.calendar}</span>
                        </h6>
                        <div id="agrid-calendar" />
                        <Calendar onChange={() => {}} />
                    </div>

                    <Divider />
                </>
            )}

            {
                <List>
                    <ListItem
                        button
                        key={'Déconnexion'}
                        onClick={() => {
                            event('Logout', `${selectedSite?.Name}`)
                            logout()
                        }}
                    >
                        <ListItemIcon>
                            <LogoutIcon color={'primary'} />
                        </ListItemIcon>
                        <ListItemText
                            primary={'Déconnexion'}
                            style={{
                                color: textBlue,
                            }}
                        />
                    </ListItem>
                </List>
            }
        </div>
    )

    const container = window !== undefined ? () => window.document.body : undefined
    return (
        <Box sx={{ display: 'flex', zIndex: 1 }}>
            {!onboardingDone && (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.1)',
                        zIndex: 200000,
                    }}
                >
                    <OnboardingComponent onboardingStep={onboardingStep} setOnboardingStep={setOnboardingStep} />
                </div>
            )}
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    ml: { md: `${drawerWidth}px` },
                }}
                style={{ zIndex: 10 }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            // width: width < 700 ? "auto" : "100%",
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                // width: width < 700 ? "auto" : "100%",
                                alignItems: 'center',
                                // width: "100%",
                            }}
                        >
                            <Box
                                sx={{
                                    display: {
                                        xs: props.showSites || props.showObjects ? 'none' : 'block',
                                        sm: props.showSites || props.showObjects ? 'none' : 'block',
                                        md: 'block',
                                    },
                                }}
                            >
                                {props.title && !props?.titleClick ? (
                                    <Typography variant="h6" noWrap component="div" style={{ fontSize: 16 }}>
                                        {props.title}
                                    </Typography>
                                ) : props?.title ? (
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        component="div"
                                        style={{ cursor: 'pointer', fontSize: 16 }}
                                        onClick={props.titleClick}
                                    >
                                        {props.title}
                                    </Typography>
                                ) : null}
                            </Box>
                            {props?.title && (
                                <Box
                                    sx={{
                                        display: {
                                            xs: 'none',
                                            sm: 'none',
                                            md: props.showSites ? 'block' : 'none',
                                        },
                                        padding: 1,
                                    }}
                                >
                                    /
                                </Box>
                            )}

                            <Box
                                sx={{
                                    display: {
                                        xs: props.showObjects && selectedObject?.ObjectId ? 'none' : 'block',
                                        sm: props.showObjects && selectedObject?.ObjectId ? 'none' : 'block',
                                        md: 'block',
                                    },
                                }}
                                id="sites-select"
                            >
                                {props.showSites && selectedSite?.Hash && (
                                    <NavSelect
                                        disabled={
                                            props.showObjects && selectedObject?.ObjectId && !props?.forceShowSite
                                        }
                                        label="Sites"
                                        key="Sites"
                                        items={
                                            props?.globalSite
                                                ? [
                                                      props?.globalSite,
                                                      ...(me?.sites || []).map((el) => {
                                                          return { value: el.Hash, label: el.Name }
                                                      }),
                                                  ]
                                                : me?.sites.map((el) => {
                                                      return { value: el.Hash, label: el.Name }
                                                  })
                                        }
                                        value={selectedSite?.Hash}
                                        onChange={(e) => {
                                            if (e.target.value === '_group') {
                                                navigate('/dashboard')
                                                // window.location.reload();
                                            } else changeSelectedSite(e.target.value)
                                        }}
                                    ></NavSelect>
                                )}
                            </Box>
                            {props?.buildingComponent}
                            <Box
                                sx={{
                                    display: {
                                        xs: 'none',
                                        sm: 'none',
                                        md: props.showObjects && selectedObject?.ObjectId ? 'block' : 'none',
                                    },
                                    padding: 1,
                                }}
                            >
                                /
                            </Box>
                            {props.showObjects && selectedObject?.ObjectId && (
                                <NavSelect
                                    label="Objects"
                                    key="Objects"
                                    items={[
                                        props.hideSiteInObjects
                                            ? [...[]]
                                            : [
                                                  ...objects
                                                      .filter((el) => el?.ObjectTypeId === 1)
                                                      .map((el) => {
                                                          return {
                                                              value: el.ObjectId,
                                                              label: el?.Name?.toUpperCase(),
                                                          }
                                                      }),
                                              ],
                                        ...objects
                                            ?.filter((el) => el?.ObjectTypeId !== 3 && el?.ObjectTypeId !== 1)
                                            ?.sort((a, b) => {
                                                return (
                                                    isFinite(a?.Name?.[0]) - isFinite(b?.Name?.[0]) ||
                                                    a?.Name?.localeCompare(b?.Name, undefined, {
                                                        numeric: true,
                                                        sensitivity: 'base',
                                                    })
                                                )
                                            })
                                            .map((el) => {
                                                return {
                                                    value: el.ObjectId,
                                                    label: el?.Name?.toUpperCase(),
                                                }
                                            }),
                                    ]}
                                    value={selectedObject?.ObjectId}
                                    onChange={(e) => changeSelectedObject(e.target.value)}
                                ></NavSelect>
                            )}
                        </div>
                        {props.floatRightButton && <div>{props.floatRightButton}</div>}
                        {props.showNavCalendar && <NavCalendarComponent />}
                    </div>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen || (!onboardingDone && onboardingStep > 0)}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            borderRight: 0,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    zIndex: 1,
                    height: '100%',
                    paddingLeft: width < 700 ? 0 : '24',
                    paddingRight: width < 700 ? 0 : '24',
                }}
            >
                <Toolbar />
                {/* <FeedBackButton /> */}
                {props.children}
            </Box>
        </Box>
    )
}

export default Layout
