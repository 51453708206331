/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState, useRef } from 'react'
import { CardHeader, CardBody } from 'reactstrap'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import { useSelector } from 'react-redux'
import useLocale from '../../utils/locale/locale.hook'
import { getIndicators } from '../../services/data.service'
import { Radar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend as ChartjsLegend,
} from 'chart.js'
import { Backdrop, CircularProgress, Card, CardContent, Grid, Box } from '@mui/material'
import PieComponent from '../../components/Charts/pie.component'
import InfoIcon from '@mui/icons-material/Info'
import Layout from '../../components/Global/layout.component'
import NoDataComponent from '../../components/Global/no-data.component'
import InfoBar from '../../components/Global/info-bar.component'
import ScoreExplanation from '../../components/Sidebar/score-explanation.component'
import YukaComponent from '../../components/Charts/yuka.component'
import HeartBeatSvg from '../../assets/img/other/heartbeat.svg'
import BasicDoughnutComponent from '../../components/Charts/basicDoughnut.component'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { TitleComponent, PageTitleComponent, DescriptionComponent } from '../../components/Basic/library'
import 'moment/locale/fr'
import { electricGreen, lightGrey, linkBlue, electricOrange, deepBlue } from '../../components/Basic/colors'
import { BuildingComponent } from '../../components/Site/buildings.component'
import { fetchBuildings } from '../../services/site.service'
import { ContainerComponent } from '../../components/Global/container.component'
import { useNavigate } from 'react-router-dom'

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, ChartjsLegend)

const LineOptions = (indicators, start, end) => {
    return {
        data: (canvas) => {
            if (canvas) {
                var ctx = canvas.getContext('2d')
                var gradientFill = ctx.createLinearGradient(0, 0, 0, 1000)
                gradientFill.addColorStop(0, 'rgba(83, 209, 134, 0.1)')
                gradientFill.addColorStop(1, 'rgba(83, 209, 134, 0.4)')
                var gradientFill2 = ctx.createLinearGradient(0, 0, 0, 600)
                gradientFill2.addColorStop(0, 'rgba(45, 202, 213, 0.1)')
                gradientFill2.addColorStop(1, 'rgba(45, 202, 213, 0.4)')

                return {
                    labels: indicators?.mean_temperatures_array?.map((el) => {
                        let days = moment(end).diff(moment(start), 'days')
                        if (days > 3) return moment(el?.Start_DateTime).format('DD/MM/YYYY')
                        else return moment(el?.Start_DateTime).format('DD/MM/YYYY, HH:mm')
                    }),
                    datasets: [
                        {
                            label: 'Ext.',
                            data: indicators?.mean_temperatures_ext_array?.map((el) => el?.['2mTemperature'] - 273),
                            fill: true,
                            borderColor: 'rgba(83, 209, 134, 0.8)',
                            backgroundColor: gradientFill,
                        },
                        {
                            label: 'Int.',
                            data: indicators?.mean_temperatures_array?.map((el) => el?.Value),
                            fill: true,
                            borderColor: 'rgba(45, 202, 213, 0.8)',
                            backgroundColor: gradientFill2,
                        },
                    ],
                }
            } else {
                return null
            }
        },
    }
}

const Dot = ({ color }) => {
    return (
        <div
            style={{
                width: '9px',
                height: '9px',
                borderRadius: '15px',
                background: color,
                marginRight: 5,
            }}
        />
    )
}

export const Legend = ({ legends, style, legendStyle }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                ...style,
            }}
        >
            {legends?.map((el) => {
                return (
                    <div
                        style={{
                            fontSize: 12,
                            display: 'flex',
                            alignItems: 'center',
                            ...legendStyle,
                        }}
                        key={el.content}
                    >
                        <div style={{ width: 10, marginRight: 2 }}>
                            <Dot color={el.color} />
                        </div>
                        <DescriptionComponent content={el.content} />
                    </div>
                )
            })}
        </div>
    )
}

const CarouselArrows = ({ setSmSelected, smSelected }) => {
    return (
        <Grid item xs={12} md={12}>
            <Box
                sx={{
                    display: { xs: 'flex', sm: 'none', md: 'none' },
                    justifyContent: 'space-between',

                    paddingBottom: { xs: 2, sm: 0, md: 0 },
                }}
            >
                <div
                    onClick={() => {
                        if (smSelected > 0) setSmSelected((smSelected - 1) % 3)
                    }}
                    style={{
                        padding: 2,
                        border: `1px solid ${smSelected === 0 ? lightGrey : deepBlue}`,
                        borderRadius: '50%',
                        width: 25,
                        height: 25,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ArrowBackIosIcon
                        style={{
                            color: smSelected === 0 ? lightGrey : deepBlue,
                            fontSize: 16,
                            marginLeft: 5,
                        }}
                    />
                </div>
                <div
                    onClick={() => {
                        if (smSelected < 2) setSmSelected((smSelected + 1) % 3)
                    }}
                    style={{
                        color: smSelected === 2 ? lightGrey : deepBlue,
                        padding: 2,
                        border: `1px solid ${smSelected === 2 ? lightGrey : deepBlue}`,
                        borderRadius: '50%',
                        width: 25,
                        height: 25,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ArrowForwardIosIcon
                        style={{
                            color: smSelected === 2 ? lightGrey : deepBlue,
                            fontSize: 16,
                        }}
                    />
                </div>
            </Box>
        </Grid>
    )
}

const SitePage = () => {
    const lineRef = useRef()
    const currentPath = useSelector((state) => state.globalStore.path)
    const calendarStore = useSelector((state) => state.calendarStore)
    const [smSelected, setSmSelected] = useState(0)
    const { width, selectedSite } = useSelector((state) => state.globalStore)
    const [buildings, setBuildings] = useState([])

    const [info, setInfo] = useState(null)

    const locale = useLocale()

    const [loading, setLoading] = useState(true)
    const [indicators, setIndicators] = useState(null)
    const [score, setScore] = useState(null)
    const [color, setColor] = useState('#E55E33')
    const navigate = useNavigate()
    useEffect(() => {
        setColor(
            score > 80
                ? '#00B86B'
                : score > 60
                  ? '#33EB9E'
                  : score > 40
                    ? '#66F0B6'
                    : score > 20
                      ? '#FAD200'
                      : '#E55E33'
        )
    }, [score])
    const fetchIndicators = async () => {
        setIndicators(null)
        setLoading(true)
        try {
            if (currentPath) {
                let siteHash = selectedSite?.Hash
                let res = await getIndicators(siteHash, {
                    startDate: calendarStore.start,
                    endDate: calendarStore.end,
                })
                if (res) {
                    setIndicators(res)
                    let newScore = res?.site_grade?.[0]?.percentage ? parseInt(res?.site_grade?.[0]?.percentage) : null
                    if (newScore) {
                        setScore(newScore)
                    }

                    // //computeScore(res);
                    // if (res?.pilot) setPilot(1);
                }
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }

    // useEffect(() => {
    //   if (selectedSite) {
    //     fetchIndicators();
    //   }
    // }, [selectedSite]);

    useEffect(() => {
        //fetchData();
        if (selectedSite) {
            fetchIndicators()
        }
    }, [selectedSite, calendarStore.start, calendarStore.end])

    useEffect(() => {
        const init = async () => {
            let res = await fetchBuildings(selectedSite?.Hash)
            setBuildings(res?.buildings || [])
        }
        if (selectedSite?.Hash) {
            init()
        }
    }, [selectedSite])

    return (
        <Layout
            showSites={true}
            title={
                <div style={{ color: 'white' }}>
                    {/* <Link to="/dashboard" style={{ color: "white" }}>
            Dashboard
          </Link>{" "} */}
                    Dashboard
                </div>
            }
            buildingComponent={
                buildings?.length > 1 && (
                    <BuildingComponent
                        buildings={buildings}
                        // setSelectedBuildingId={setSelectedBuildingId}
                        selectedSite={selectedSite}
                        disabled={false}
                    />
                )
            }
            //floatRightButton={width > 900 ? <NavCalendar /> : null}
        >
            <ContainerComponent>
                {info && <InfoBar info={info} close={() => setInfo(null)} />}
                <PageTitleComponent
                    title={`Du ${moment(calendarStore.start).format(
                        'DD MMMM'
                    )} au ${moment(calendarStore.end).format('DD MMMM')}`}
                />

                <Grid container spacing={{ md: 2, sm: 2, xs: 0 }}>
                    <CarouselArrows smSelected={smSelected} setSmSelected={setSmSelected} />
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                display: {
                                    xs: smSelected === 0 ? 'block' : 'none',
                                    sm: 'block',
                                    md: 'block',
                                },
                                height: '100%',
                            }}
                        >
                            <Card style={{ margin: 0, height: '100%' }}>
                                <CardHeader
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: 0,
                                    }}
                                >
                                    <TitleComponent
                                        title={`${locale?.['pages']['site']['spendings']} ${
                                            indicators?.ipmvp && Object.keys(indicators?.ipmvp)?.length > 0 ? '*' : ''
                                        }`}
                                    />

                                    <div
                                        style={{
                                            display: 'flex',
                                            fontSize: 10,
                                            alignItems: 'center',
                                            color: linkBlue,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            setInfo({
                                                content:
                                                    'Durant sa période d’initialisation, le logiciel AGRID apprend vos usages ainsi que le comportement de vos appareils. Lorsque le pilote automatique est activé, nous sommes ainsi en mesure de comparer la consommation de vos appareils avec celle qui aurait été constatée sans pilote.',
                                                title: 'Comprendre mon gain',
                                            })
                                        }
                                    >
                                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                            Comprendre mon gain
                                        </Box>
                                        <InfoIcon style={{ color: linkBlue, width: 15, marginLeft: 2 }} />
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    {indicators?.energy_savings_real?.mean > 0 ? (
                                        <div
                                            style={{
                                                height: 300,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <BasicDoughnutComponent
                                                width={'150px!important'}
                                                height={'150px!important'}
                                                labels={['Economies réalisées', 'Economies théoriques réalisables']}
                                                datasets={[
                                                    {
                                                        label: 'Dataset 1',
                                                        data: [
                                                            indicators?.energy_savings_real?.mean * 100,
                                                            (indicators?.energy_savings_ideal_agrid?.mean -
                                                                indicators?.energy_savings_real?.mean) *
                                                                100,
                                                        ],
                                                        backgroundColor: [electricGreen, lightGrey],
                                                    },
                                                ]}
                                            >
                                                <PageTitleComponent
                                                    title={`${(indicators?.energy_savings_real?.mean * 100)?.toFixed(
                                                        1
                                                    )}%`}
                                                    style={{ fontSize: 40 }}
                                                />
                                            </BasicDoughnutComponent>
                                            <Legend
                                                style={{ marginTop: 20 }}
                                                legends={[
                                                    {
                                                        color: electricGreen,
                                                        content: 'Economies réalisées',
                                                    },
                                                    {
                                                        color: lightGrey,
                                                        content: 'Economies théoriques réalisables',
                                                    },
                                                ]}
                                            />
                                        </div>
                                    ) : (
                                        <NoDataComponent navigate={navigate} />
                                    )}
                                </CardBody>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                display: {
                                    xs: smSelected === 1 ? 'block' : 'none',
                                    sm: 'block',
                                    md: 'block',
                                },
                                height: '100%',
                            }}
                        >
                            <Card
                                style={{
                                    margin: 0,
                                    height: '100%',
                                }}
                            >
                                <CardHeader
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: 0,
                                    }}
                                >
                                    <TitleComponent title={`${locale?.['pages']['site']['note']}`} />

                                    <div
                                        style={{
                                            display: 'flex',
                                            fontSize: 10,
                                            alignItems: 'center',
                                            color: '#2196f3',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            setInfo({
                                                content: (
                                                    <ScoreExplanation
                                                        score={score}
                                                        color={color}
                                                        indicators={indicators}
                                                    />
                                                ),
                                                title: 'Améliorer mon score',
                                            })
                                        }
                                    >
                                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                            Comprendre mon score
                                        </Box>
                                        <InfoIcon style={{ color: linkBlue, width: 15, marginLeft: 2 }} />
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item xs={12} sm={12} md={12} style={{ position: 'relative' }}>
                                            {/* <YukaComponent color={color} score={score} /> */}
                                            <Box
                                                sx={{
                                                    display: {
                                                        sm: 'none',
                                                        xs: 'none',
                                                        md: 'none',
                                                        lg: 'block',
                                                    },
                                                }}
                                            >
                                                <Legend
                                                    style={{ position: 'absolute', top: 0 }}
                                                    legends={[
                                                        {
                                                            color:
                                                                indicators?.ratio_piloted_zones?.[0]?.total?.toFixed(
                                                                    1
                                                                ) > 70
                                                                    ? electricGreen
                                                                    : electricOrange,
                                                            content: 'P: % pilotée',
                                                        },
                                                        {
                                                            color:
                                                                indicators?.ratio_constraints_respect?.[0]?.total?.toFixed(
                                                                    1
                                                                ) > 70
                                                                    ? electricGreen
                                                                    : electricOrange,
                                                            content: 'C: % configuration',
                                                        },
                                                        {
                                                            color:
                                                                indicators?.change_percentage?.[0]?.percentage?.toFixed(
                                                                    1
                                                                ) > 70
                                                                    ? electricGreen
                                                                    : electricOrange,
                                                            content: 'A: % automatisation',
                                                        },
                                                    ]}
                                                />
                                            </Box>
                                            <Radar
                                                // height="309px"
                                                // width="309px"
                                                data={{
                                                    labels: ['P', 'C', 'A'],
                                                    datasets: [
                                                        {
                                                            label: selectedSite?.Name,
                                                            data: [
                                                                indicators?.ratio_piloted_zones?.[0]?.total?.toFixed(1),
                                                                indicators?.ratio_constraints_respect?.[0]?.total?.toFixed(
                                                                    1
                                                                ),
                                                                indicators?.change_percentage?.[0]?.percentage?.toFixed(
                                                                    1
                                                                ),
                                                            ],
                                                            fill: true,
                                                            backgroundColor: 'rgba(82, 209, 135, 0.2)',
                                                            borderColor: 'rgba(82, 209, 135, 0.5)',
                                                            pointBackgroundColor: '#52d187',
                                                            pointBorderColor: '#52d187',
                                                            pointHoverBackgroundColor: '#fff',
                                                            pointHoverBorderColor: '#52d187',
                                                        },
                                                    ],
                                                }}
                                                options={{
                                                    responsive: true,
                                                    plugins: {
                                                        legend: {
                                                            display: false,
                                                        },
                                                    },
                                                    animation: {
                                                        animateScale: true,
                                                        animateRotate: true,
                                                    },
                                                    scales: {
                                                        r: {
                                                            pointLabels: {
                                                                font: {
                                                                    size: 10,
                                                                },
                                                            },
                                                            suggestedMin: 0,
                                                            suggestedMax: 100,
                                                        },
                                                    },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} style={{ marginBottom: 10, marginTop: -30 }}>
                                            <Box
                                                sx={{
                                                    display: {
                                                        xs: 'block',
                                                        sm: 'block',
                                                        md: 'block',
                                                        lg: 'none',
                                                    },
                                                    marginBottom: 4,
                                                }}
                                            >
                                                <Legend
                                                    legends={[
                                                        {
                                                            color:
                                                                indicators?.ratio_piloted_zones?.[0]?.total?.toFixed(
                                                                    1
                                                                ) > 70
                                                                    ? electricGreen
                                                                    : electricOrange,
                                                            content: 'P: % pilotée',
                                                        },
                                                        {
                                                            color:
                                                                indicators?.ratio_constraints_respect?.[0]?.total?.toFixed(
                                                                    1
                                                                ) > 70
                                                                    ? electricGreen
                                                                    : electricOrange,
                                                            content: 'A: % automatisation',
                                                        },
                                                        {
                                                            color:
                                                                indicators?.change_percentage?.[0]?.percentage?.toFixed(
                                                                    1
                                                                ) > 70
                                                                    ? electricGreen
                                                                    : electricOrange,
                                                            content: 'C: % configuration',
                                                        },
                                                    ]}
                                                />
                                            </Box>
                                            <YukaComponent color={color} score={score} />
                                        </Grid>
                                        {/* <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 70,
                        color: color,
                      }}
                    >
                      {score}
                    </div>
                  </Grid> */}
                                    </Grid>
                                </CardBody>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Box
                            sx={{
                                display: {
                                    xs: smSelected === 2 ? 'block' : 'none',
                                    sm: 'block',
                                    md: 'block',
                                },
                                height: '100%',
                            }}
                        >
                            <Card
                                style={{
                                    margin: 0,
                                    height: '100%',
                                    background: 'white',
                                }}
                                //class={pilot ? "pilot-on" : "pilot-off"}
                            >
                                <CardHeader
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: 0,
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {/* <img src={PilotSvg} style={{ width: 20 }} /> */}
                                        <TitleComponent
                                            title={`${locale?.['pages']['site']['automatisation']}`}
                                            style={{ margin: 0, marginLeft: 4 }}
                                        />
                                    </div>
                                </CardHeader>
                                <CardContent>
                                    <div>
                                        {indicators?.change_percentage?.[0]?.percentage &&
                                        indicators?.change_percentage?.[0]?.percentage !== null ? (
                                            <div
                                                style={{
                                                    height: 300,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <PieComponent
                                                    labels={['Manuel', 'Auto']}
                                                    datasets={[
                                                        {
                                                            label: 'Automatisation',
                                                            data: [
                                                                1 -
                                                                    indicators?.change_percentage?.[0]?.percentage /
                                                                        100,
                                                                indicators?.change_percentage?.[0]?.percentage / 100,
                                                            ],
                                                            backgroundColor: [lightGrey, electricGreen],
                                                        },
                                                    ]}
                                                    //width={"100px"}
                                                    //height={"200px"}
                                                />

                                                <Legend
                                                    style={{ marginTop: 20 }}
                                                    legends={[
                                                        {
                                                            color: electricGreen,
                                                            content: 'Automatisation de notre algorithme',
                                                        },
                                                        {
                                                            color: lightGrey,
                                                            content: 'Actions manuelles par les utilisateurs',
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        ) : (
                                            <NoDataComponent navigate={navigate} />
                                        )}
                                    </div>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ paddingTop: { xs: 2, sm: 0, md: 0 } }}>
                        <Card>
                            <CardHeader>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TitleComponent title={`${locale?.['pages']['site']['averageTemperature']}`} />
                                </div>
                            </CardHeader>

                            <CardContent>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        paddingBottom: 20,
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={HeartBeatSvg} style={{ width: 30, marginRight: 10 }} />
                                        {indicators?.mean_temperatures_array?.length > 0 && (
                                            <div style={{ color: '#B7BCC7', fontSize: 12 }}>
                                                MIN -{' '}
                                                {Math.min(
                                                    ...indicators?.mean_temperatures_array.map((el) => el.Value)
                                                ).toFixed(1)}
                                                °C MAX -{' '}
                                                {Math.max(
                                                    ...indicators?.mean_temperatures_array.map((el) => el.Value)
                                                ).toFixed(1)}
                                                °C
                                            </div>
                                        )}
                                    </div>
                                    <Legend
                                        legends={[
                                            {
                                                color: 'rgba(45, 202, 213, 0.8)',
                                                content: ' Température intérieure mesurée',
                                            },
                                            {
                                                color: 'rgba(83, 209, 134, 0.8)',
                                                content: 'Température extérieure mesurée',
                                            },
                                        ]}
                                        style={{ width: 'auto' }}
                                    />
                                </div>
                                {indicators?.mean_temperatures_array?.length > 0 ? (
                                    // <BarComponent data={data} timeZone={timeZone} />
                                    <div style={{ width: '100%' }}>
                                        <Line
                                            // data={formatData(
                                            //   indicators,
                                            //   calendarStore.start,
                                            //   calendarStore.end
                                            // )}
                                            data={LineOptions(indicators, calendarStore.start, calendarStore.end)?.data}
                                            options={{
                                                responsive: true,
                                                scales: {
                                                    // y: {
                                                    //   min:
                                                    //     parseInt(
                                                    //       Math.min(
                                                    //         ...indicators?.mean_temperatures_array.map(
                                                    //           (el) => el.Value - 273
                                                    //         )
                                                    //       ) - 5
                                                    //     ) || 0,
                                                    //   max:
                                                    //     parseInt(
                                                    //       Math.max(
                                                    //         ...indicators?.mean_temperatures_array.map(
                                                    //           (el) => el.Value - 273
                                                    //         )
                                                    //       ) + 5
                                                    //     ) || 30,
                                                    // },
                                                    x: {
                                                        grid: {
                                                            display: width < 900 ? false : true,
                                                            drawBorder: width < 900 ? false : true,
                                                        },
                                                        ticks: {
                                                            display: width < 900 ? false : true,
                                                            maxTicksLimit: 10,
                                                        },
                                                    },
                                                },
                                                plugins: {
                                                    legend: {
                                                        display: false,
                                                    },
                                                    tooltip: {
                                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                        borderColor: 'rgb(0,0,0)',
                                                        borderWidth: 1,
                                                        bodyColor: '#616161',
                                                        titleColor: '#616161',
                                                        callbacks: {
                                                            label: function (tooltipItem, _) {
                                                                return parseFloat(tooltipItem?.raw).toFixed(1) + ' °C'
                                                            },
                                                        },
                                                    },
                                                },
                                                elements: {
                                                    line: {
                                                        tension: 0.4,
                                                    },
                                                },
                                            }}
                                            // redraw
                                            ref={lineRef}
                                        />
                                    </div>
                                ) : !loading ? (
                                    <NoDataComponent navigate={navigate} />
                                ) : null}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={2}></Grid>

                {loading && (
                    <Backdrop open={true} style={{ color: '#fff', zIndex: 1000 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </ContainerComponent>
        </Layout>
    )
}

export default SitePage
