import { FC } from 'react'
import { MeasurementsQueryResult } from '../../../timeSeries/types'
import { AggregationType, aggregateSeries } from '../../../timeSeries/aggregation/aggregateSeries'
import { SeriesGroupByKey, groupSeries } from '../../../timeSeries/aggregation/groupSeries'
import sumBy from 'lodash/sumBy'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'

export interface TimeSeriesTableChartOptions {
    groupBy: SeriesGroupByKey
    aggType: AggregationType
    showPercentage?: boolean
    showTotal?: boolean
}

interface TimeSeriesPieTableProps {
    options?: TimeSeriesTableChartOptions
    series: MeasurementsQueryResult[]
}

const formatNumber = (value: number): string => Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(value)
// TODO pass as option

const TableChart: FC<TimeSeriesPieTableProps> = ({ series, options }) => {
    const groupedSeries = groupSeries(
        series.map((s) => s.data),
        options?.groupBy || 'subType'
    )
    const aggregatedSeries = Object.entries(groupedSeries).map(([key, values]) => {
        return {
            name: key,
            agg: aggregateSeries(values, options?.aggType || 'sum'),
            unit: values[0].unit,
        }
    })
    const total = sumBy(aggregatedSeries, 'agg')
    const aggregatedSeriesWithPercentage = aggregatedSeries.map((s) => ({
        ...s,
        percentage: (s.agg / total) * 100,
    }))
    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {aggregatedSeriesWithPercentage.map((s) => (
                        <TableRow key={s.name}>
                            <TableCell>{s.name}</TableCell>
                            <TableCell>
                                {formatNumber(s.agg)} {s.unit || ''}
                            </TableCell>
                            {options?.showPercentage && <TableCell>{s.percentage.toFixed(2)}%</TableCell>}
                        </TableRow>
                    ))}
                    {options?.showTotal && (
                        <TableRow>
                            <TableCell>
                                <b>Total</b>
                            </TableCell>
                            <TableCell>
                                {formatNumber(total)} {aggregatedSeries[0].unit || ''}
                            </TableCell>
                            {options?.showPercentage && <TableCell>100%</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TableChart
