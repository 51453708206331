import { useQuery } from '@tanstack/react-query'
import { getSiteFeeds } from '@/services/meters.service'
import { Feed } from '@/types/meters'
import { useParams } from 'react-router-dom'
export const useFeeds = () => {
    const { siteHash } = useParams<{ siteHash: string }>()
    if (!siteHash) {
        throw new Error('siteHash is required')
    }
    const feedsQuery = useQuery<Feed[]>({
        queryKey: ['feeds', siteHash],
        queryFn: () => getSiteFeeds(siteHash),
    })
    return {
        isLoading: feedsQuery.isLoading,
        feeds: feedsQuery.data || [],
    }
}
