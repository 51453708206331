import { PeriodShortcut, BasePeriodShortcut } from './periodShortcut'
import CalendarSlice from '@/store/slices/calendar.slice'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

const BASE_SHORTCUTS: BasePeriodShortcut[] = [
    // this month
    {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
        label: moment().format('MMMM YYYY'),
    },
    // last month
    {
        startDate: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        label: moment().subtract(1, 'month').format('MMMM YYYY'),
    },
    // this year
    {
        startDate: moment().startOf('year').format('YYYY-MM-DD'),
        endDate: moment().endOf('year').format('YYYY-MM-DD'),
        label: moment().format('YYYY'),
    },
    // last year
    {
        startDate: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
        label: moment().subtract(1, 'year').format('YYYY'),
    },
]

export const usePeriodShortcuts = (): { shortcuts: PeriodShortcut[] } => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const { start, end } = useSelector((state) => state.calendarStore) as { start: string; end: string }
    const dispatch = useDispatch()
    const updatePeriod = (startDate: string, endDate: string) => {
        dispatch(
            CalendarSlice.actions.updateCalendar({
                start: moment(startDate).toISOString(),
                end: moment(endDate).toISOString(),
            })
        )
    }
    const isPeriodActive = (startDate: string, endDate: string) =>
        moment(start).isSame(startDate) && moment(end).isSame(endDate)
    const shortcuts = BASE_SHORTCUTS.map(({ startDate, endDate, label }) => ({
        startDate,
        endDate,
        label,
        active: isPeriodActive(startDate, endDate),
        onSelect: () => updatePeriod(startDate, endDate),
    }))
    return { shortcuts }
}
