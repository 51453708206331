import { Measurement, MeasurementAggregation, MeasurementGroupBy } from '@/types/meters'
import { MeasurementsQueryResult } from './types'
import { useQueries } from '@tanstack/react-query'
import { SeriesFilter, filterSeries } from './timeSeriesFilters'
import { getSeriesMeasurements } from '@/services/meters.service'
import { useTimeSeries } from './useTimeSeries'
import { useParams } from 'react-router-dom'
import { getUnitConverterFunction } from './aggregation/unitConverters'
import { useTranslation } from 'react-i18next'

export interface TimeSeriesAggregateOptions {
    groupBy?: MeasurementGroupBy
    aggBy?: MeasurementAggregation[]
    unit?: string | null
}

export interface SeriesParsingOptions {
    labelGetter?: (series: MeasurementsQueryResult['data']) => string
}

export const useTimeSeriesMeasurements = (
    filters: SeriesFilter,
    parsingOptions: SeriesParsingOptions = {},
    options?: TimeSeriesAggregateOptions
) => {
    const { siteHash } = useParams()
    if (!siteHash) {
        throw new Error('useTimeSeriesMeasurements must be used within a site route, missing siteHash parameter')
    }
    const { series, isLoading, period } = useTimeSeries()
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.units' })
    const { from, to } = period

    const filteredSeries = filterSeries(series, filters)

    const measurementsQueries: MeasurementsQueryResult[] = useQueries({
        queries: filteredSeries.map((series) => ({
            queryKey: ['measurements', series.id, { from, to, ...options }],
            queryFn: async () =>
                getSeriesMeasurements(siteHash, series.meterId, series.id, {
                    from,
                    to,
                    ...options,
                }),
        })),
        combine: (results) => {
            return results.map((current, index) => {
                const series = filteredSeries[index]
                const unitConversionRequired = options?.unit && series.unit !== options.unit
                const targetUnit = options?.unit || series.unit
                const translatedUnit = targetUnit ? t(targetUnit, { defaultValue: targetUnit }) : targetUnit
                const unitConverter = unitConversionRequired
                    ? getUnitConverterFunction(series.unit as string | null, options.unit as string)
                    : undefined
                const rawMeasurements = current.data || ([] as Measurement[])
                const measurements = unitConverter ? rawMeasurements.map(unitConverter) : rawMeasurements
                const data = { ...series, label: series.name, unit: targetUnit, measurements }
                const label = parsingOptions.labelGetter ? parsingOptions.labelGetter(data) : undefined
                return {
                    isLoading: current.isLoading,
                    isError: current.isError,
                    data: { ...data, label, unit: translatedUnit },
                }
            })
        },
    })

    return { isLoading, series: measurementsQueries }
}
