import { Routes, Route } from 'react-router-dom'
import RealtimeObjectTelecommand from './zone.page'
import ObjectHistory from './ObjectHistory'
import Layout from './layout'
import { ObjectRealtimeTabRoutes } from './objectRealtimeRoutes'

const RealtimeObject = () => {
    return (
        <Routes>
            <Route element={<Layout />} path="/">
                <Route path={ObjectRealtimeTabRoutes.TELECOMMAND} element={<RealtimeObjectTelecommand />} />
                <Route path={ObjectRealtimeTabRoutes.HISTORY} element={<ObjectHistory />} />
            </Route>
        </Routes>
    )
}

export default RealtimeObject
