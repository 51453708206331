import { Routes, Route } from 'react-router-dom'
import SiteDashboardLayout from './layout'
import DashboardWrapper from './DashboardWrapper'

const SiteDashboardRoutes = () => {
    return (
        <Routes>
            <Route element={<SiteDashboardLayout />} path="/">
                <Route path="/" element={<DashboardWrapper />} />
                <Route path=":dashboardId/*" element={<DashboardWrapper />} />
            </Route>
        </Routes>
    )
}
export default SiteDashboardRoutes
