import Highcharts from 'highcharts'
import { Measurement } from '@/types/meters'
import { MeasurementsQueryResult } from '../../../../timeSeries/types'
import useHighcharts from '@/components/Charts/useHighcharts'
import { CommonChartOptions } from '../../commonChartOptions'

export interface TimeSeriesLineChartOptions extends CommonChartOptions {
    maxThresholdValueGetter?: (series: MeasurementsQueryResult['data']) => number | undefined
    maxThresholdLabelFormatter?: (value: number) => string
}

const chartBaseFormatOptions: Highcharts.Options = {
    title: {
        text: '',
    },
    chart: {
        backgroundColor: 'transparent',
    },
    exporting: {
        enabled: false,
    },
    yAxis: {
        title: {
            text: '',
        },
    },
    plotOptions: {
        series: {
            animation: false,
        },
    },
    tooltip: {
        shared: true,
    },
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parseMeasurementValue = ({ timestamp, ...values }: Measurement): number => {
    if (values.avg) {
        return values.avg
    }
    const firstKey = Object.keys(values)[0] as keyof Omit<Measurement, 'timestamp'>

    if (!firstKey) {
        throw new Error('No value found in measurement')
    }

    return values[firstKey] as number
}

export const parseMeasurement = (measurement: Measurement, options?: { isDaily?: boolean; isRange?: boolean }) => {
    const x = new Date(measurement.timestamp).getTime()
    if (options?.isRange) {
        return [x, measurement.min, measurement.max]
    }
    const y = parseMeasurementValue(measurement)
    if (options?.isDaily) {
        return {
            x,
            y,
        }
    }
    return [x, y]
}

export const getSeriesTimeSpanDays = (measurements: Measurement[]): number => {
    if (measurements.length < 2) {
        return 0
    }

    const first = new Date(measurements[0].timestamp).getTime()
    const last = new Date(measurements[measurements.length - 1].timestamp).getTime()

    return Math.round((last - first) / (1000 * 60 * 60 * 24))
}

export const getPlotlineOptions = (
    value: number,
    name: string,
    color: string,
    label: string
): Highcharts.YAxisPlotLinesOptions => ({
    value,
    color,
    id: `plot-line-${name}`,
    width: 1,
    zIndex: 1,
    dashStyle: 'Dash',
    label: {
        text: label,
        align: 'right',
        x: -10,
        style: {
            color,
        },
    },
})

const getSeriesExtraOptions = (series: MeasurementsQueryResult['data'], options?: TimeSeriesLineChartOptions) => {
    const { maxThresholdValueGetter } = options || {}
    const max = maxThresholdValueGetter ? maxThresholdValueGetter(series) : undefined
    return { max }
}

const getPlotlines = (series: (MeasurementsQueryResult & { color: unknown })[], options?: TimeSeriesLineChartOptions) =>
    series
        .map((s) => {
            const max = getSeriesExtraOptions(s.data, options).max
            if (!max) {
                return undefined
            }
            const label = options?.maxThresholdLabelFormatter ? options.maxThresholdLabelFormatter(max) : String(max)
            return getPlotlineOptions(max, s.data.meterName, s.color as string, label)
        })
        .filter(Boolean) as Highcharts.YAxisPlotLinesOptions[]

export const getLineSeriesOptions = (unit?: string | null): Highcharts.SeriesLineOptions => ({
    type: 'line',
    marker: {
        enabled: false,
    },
    lineWidth: 2,
    tooltip: {
        valueDecimals: 0,
        pointFormat: `<span style="color:{series.color}">{series.name}</span>: <b>{point.y} ${unit}</b><br/>`,
    },
})

export const areaRangeSeriesOptions: Highcharts.SeriesArearangeOptions = {
    type: 'arearange',
    lineWidth: 0,
    linkedTo: ':previous',
    fillOpacity: 0.3,
    marker: {
        enabled: false,
    },
    tooltip: {
        valueDecimals: 0,
        pointFormat:
            '<span style="color:{series.color}">Min - max</span>: <b>{point.low}</b> - <b>{point.high}</b><br/>',
    },
}

// TODO
// const formatPlotlineEvent = (value: number): Highcharts.SeriesLineOptions['events'] => ({
//     legendItemClick: function () {
//         console.log('legendItemClick', this.name, this.visible)
//         const chart = this.chart
//         const plotLineId = `plot-line-${this.name}`
//         if (this.visible) {
//             chart.yAxis[0].removePlotLine(plotLineId)
//         } else {
//             chart.yAxis[0].addPlotLine(getPlotlineOptions(value, this.name, this.color as string, ''))
//         }
//     },
// })

export const formatSeries = (
    series: MeasurementsQueryResult['data'],
    options: {
        color: string
        max?: number
    }
) => {
    const { measurements, ...s } = series
    const isDailyProfile = getSeriesTimeSpanDays(series.measurements) === 1
    const hasRange = measurements[0]?.min !== undefined && measurements[0]?.max !== undefined
    const { color, max } = options

    const zones = max
        ? [
              {
                  value: max,
                  color,
              },
              {
                  color: 'red',
              },
          ]
        : undefined
    // const plotLineEvents = max ? formatPlotlineEvent(max) : undefined
    if (hasRange) {
        return [
            {
                ...getLineSeriesOptions(s.unit),
                name: s.meterName,
                color,
                data: measurements.map((m) => parseMeasurement(m, { isDaily: isDailyProfile })),
                // events: plotLineEvents,
            },
            {
                ...areaRangeSeriesOptions,
                color,
                zones,
                data: measurements.map((m) => parseMeasurement(m, { isRange: true, isDaily: isDailyProfile })),
            },
        ]
    }
    return [
        {
            ...getLineSeriesOptions(s.unit),
            name: s.label || s.meterName,
            unit: s.unit,
            color,
            data: measurements.map((m) => parseMeasurement(m, { isDaily: isDailyProfile })),
        },
    ]
}

export const parseLineChartOptions = (
    series: (MeasurementsQueryResult & { color: unknown })[],
    options?: TimeSeriesLineChartOptions
): Highcharts.Options => {
    const isDailyProfile = getSeriesTimeSpanDays(series[0].data.measurements) === 1
    return {
        ...chartBaseFormatOptions,
        xAxis: {
            type: 'datetime',
            labels: {
                format: isDailyProfile ? '{value:%H:%M}' : undefined,
            },
        },
        yAxis: {
            ...chartBaseFormatOptions.yAxis,
            plotLines: getPlotlines(series, options),
        },
        legend: {
            enabled: !options?.disableLegend,
        },
        // tooltip: {
        //     pointFormat: `{series.name}: <b>{point.y:.0f} ${series[0].data.unit}</b>`,
        // },

        series: series
            .map(({ data, color }) =>
                formatSeries(data, { color: color as string, ...getSeriesExtraOptions(data, options) })
            )
            .flat(),
    }
}

export const useParseLineChartOptions = (options?: TimeSeriesLineChartOptions) => {
    const { getColor } = useHighcharts()

    return (series: MeasurementsQueryResult[]): Highcharts.Options => {
        return parseLineChartOptions(
            series.map((s, index) => ({
                ...s,
                color: getColor(index),
            })),

            options
        )
    }
}
