import { FC } from 'react'
import { Typography, Box } from '@mui/material'
import { useFeeds } from './useFeeds'
import { useTranslation } from 'react-i18next'
import LoadingSkeletons from './LoadingSkeleton'
import { Feed } from '@/types/meters'
import FeedCard from './FeedCard'
import { Alert, Button } from '@mui/material'
import { RoutePaths } from '@/navigation/route'
import { Link } from 'react-router-dom'

interface FeedsListProps {
    feeds: Feed[]
}

const FeedsList: FC<FeedsListProps> = ({ feeds }) => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.feeds' })
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, py: 2 }}>
            {feeds
                .sort((a, b) => (a.type > b.type ? 1 : -1))
                .map((feed) => (
                    <FeedCard key={feed.id} feed={feed} />
                ))}
            <Alert
                severity="info"
                action={
                    <Button component={Link} to={RoutePaths.Contact} color="inherit" size="small">
                        {t('addFeedCTA.actionTitle')}
                    </Button>
                }
                sx={{ py: 2, alignItems: 'center' }}
            >
                {t('addFeedCTA.title')}
            </Alert>
        </Box>
    )
}

const FeedsDashboard = () => {
    const { isLoading, feeds } = useFeeds()
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.feeds' })
    return (
        <>
            <Typography variant="h5">{t('title')}</Typography>
            {isLoading ? <LoadingSkeletons count={5} sx={{ my: 3 }} /> : <FeedsList feeds={feeds} />}
        </>
    )
}
export default FeedsDashboard
