import { FC } from 'react'
import { Feed } from '@/types/meters'
import { Card, CardProps, CardContent, Typography, Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { FeedStatusChip, FeedTypeChip } from './Chips'

interface FeedCardProps extends CardProps {
    feed: Feed
}

const FeedCard: FC<FeedCardProps> = ({ feed, ...cardProps }) => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.feeds' })
    return (
        <Card
            {...cardProps}
            sx={(theme) => ({
                borderRadius: theme.shape.borderRadius / 2,
                bporder: `1px solid ${theme.palette.divider}`,
            })}
        >
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Typography variant="h6" gutterBottom>
                            {t(`providers.${feed.provider}`, { defaultValue: feed.provider })}
                        </Typography>
                        <FeedTypeChip type={feed.type} />
                    </Box>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                        ID:&nbsp;{feed.providerPointId}
                    </Typography>
                </Box>
                <Stack>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Typography variant="caption" color="text.secondary">
                            {t('status')}
                        </Typography>
                        <FeedStatusChip status={feed.status} />
                    </Box>
                    <Typography variant="caption" color="text.secondary" mt={1}>
                        {t('created')}&nbsp;{moment(feed.createdAt).format('LLL')}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" mt={1}>
                        {t('updated')}&nbsp;{moment(feed.updatedAt).fromNow()}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default FeedCard
