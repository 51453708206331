export enum DashboardKeys {
    MAIN = 'main',
    ENERGY = 'energy',
    HVAC = 'hvac',
    METEO = 'meteo',
    OCCUPANCY = 'occupancy',
    FEEDS = 'feeds',
}

export const DEFAULT_DASHBOARD_KEY = DashboardKeys.MAIN
