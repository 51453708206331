import { ExtendedSeriesWithMeasurements, MeasurementsQueryResult } from '../../../timeSeries/types'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import Highcharts from 'highcharts'
import { parseFirstMeasurementAggOrValue } from '../utils/parseFirstMeasurementAggOrValue'
import { SeriesFilter } from '../../../timeSeries/timeSeriesFilters'
import {
    useTimeSeriesMeasurements,
    TimeSeriesAggregateOptions,
    SeriesParsingOptions,
} from '../../../timeSeries/useTimeSeriesMeasurements'
import { Skeleton } from '@mui/material'
import { CommonChartOptions } from '../commonChartOptions'

type DualAxisAllowedType = 'bar' | 'line' | 'spline'

interface AxisFormatOptions {
    type: DualAxisAllowedType
    title?: string
}

const translateToHichartsType = (type: DualAxisAllowedType): string => (type === 'bar' ? 'column' : type)

interface TimeSeriesDualAxesChartProps {
    primaryAxis: AxisFormatOptions & { series: MeasurementsQueryResult[] }
    secondaryAxis: AxisFormatOptions & { series: MeasurementsQueryResult[] }
    options?: CommonChartOptions
}

const chartFormatOptions: Highcharts.Options = {
    title: {
        text: '',
    },
    chart: {
        backgroundColor: 'transparent',
    },
    exporting: {
        enabled: false,
    },
    yAxis: {
        title: {
            text: '',
        },
    },
    plotOptions: {
        series: {
            animation: false,
            stacking: 'normal',
        },
    },
}

const formatUnit = (series: ExtendedSeriesWithMeasurements): string => {
    const unit = series.unit
    return unit ? ` ${unit}` : ''
}

const formatToHighchartsSeries = (
    series: ExtendedSeriesWithMeasurements,
    axisIndex: number,
    axisType: DualAxisAllowedType
) => ({
    name: series.label || series.meterName,
    data: series.measurements.map((m) => ({
        x: new Date(m.timestamp).getTime(),
        y: parseFirstMeasurementAggOrValue(m),
    })),
    type: translateToHichartsType(axisType),
    yAxis: axisIndex,
    tooltip: {
        valueSuffix: formatUnit(series),
    },
})

const formatTitle = (title: string | undefined, series: ExtendedSeriesWithMeasurements): string | undefined => {
    if (!title) {
        return undefined
    }
    const unit = series.unit
    return unit ? `${title} [${unit}]` : title
}

const DualAxesChart: React.FC<TimeSeriesDualAxesChartProps> = ({ primaryAxis, secondaryAxis, options }) => {
    const primaryAxisSeries = primaryAxis.series.map((s) => s.data)
    const secondaryAxisSeries = secondaryAxis.series.map((s) => s.data)
    return (
        <HighchartsChart
            title="Bar Chart"
            type="bar"
            options={{
                ...chartFormatOptions,
                xAxis: {
                    type: 'datetime',
                },
                tooltip: {
                    dateTimeLabelFormats: {
                        day: '%e %b %Y',
                        week: '%e %b %Y',
                        month: '%b %Y',
                        year: '%Y',
                    },
                    shared: true,
                    valueDecimals: 2,
                },
                yAxis: [
                    {
                        title: {
                            text: formatTitle(primaryAxis.title, primaryAxisSeries[0]),
                        },
                    },
                    {
                        title: {
                            text: formatTitle(secondaryAxis.title, secondaryAxisSeries[0]),
                        },

                        opposite: true,
                    },
                ],
                series: [
                    ...primaryAxisSeries.map((s) => formatToHighchartsSeries(s, 0, primaryAxis.type)),
                    ...secondaryAxisSeries.map((s) => formatToHighchartsSeries(s, 1, secondaryAxis.type)),
                ],
                legend: {
                    enabled: !options?.disableLegend,
                },
            }}
        />
    )
}

interface TimeSeriesDualAxesChartWrapperProps {
    primaryAxis: AxisFormatOptions & {
        filters: SeriesFilter
        parsingOptions?: SeriesParsingOptions
        aggregateOptions?: TimeSeriesAggregateOptions
    }
    secondaryAxis: AxisFormatOptions & {
        filters: SeriesFilter
        parsingOptions?: SeriesParsingOptions
        aggregateOptions?: TimeSeriesAggregateOptions
    }
    options?: CommonChartOptions
}

const DualAxesChartWrapper: React.FC<TimeSeriesDualAxesChartWrapperProps> = (props) => {
    const { primaryAxis, secondaryAxis, ...rest } = props
    const primaryAxisSeries = useTimeSeriesMeasurements(
        primaryAxis.filters,
        primaryAxis.parsingOptions,
        primaryAxis.aggregateOptions
    )
    const secondaryAxisSeries = useTimeSeriesMeasurements(
        secondaryAxis.filters,
        secondaryAxis.parsingOptions,
        secondaryAxis.aggregateOptions
    )
    const isLoading = primaryAxisSeries.isLoading || secondaryAxisSeries.isLoading
    if (isLoading) {
        return <Skeleton variant="rectangular" sx={{ height: 300 }} />
    }
    return (
        <DualAxesChart
            primaryAxis={{ ...primaryAxis, series: primaryAxisSeries.series }}
            secondaryAxis={{ ...secondaryAxis, series: secondaryAxisSeries.series }}
            {...rest}
        />
    )
}

export default DualAxesChartWrapper
