import { FC } from 'react'
import { Paper, Grid, GridProps, Typography } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'

// Flex Sizing containers
const SIZES = ['1/4', '1/3', '2/3', '1/2', '1'] as const

export type ChartSize = (typeof SIZES)[number]

const BREAKPOINTS: Record<ChartSize, Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg' | 'xl'>> = {
    '1/4': { xs: 12, md: 3 },
    '1': { xs: 12 },
    '1/2': { xs: 12, md: 6 },
    '1/3': { xs: 12, md: 4 },
    '2/3': { xs: 12, md: 8 },
}

export interface ChartContainerProps {
    title: React.ReactNode
    unit?: string | null
    size?: ChartSize
}

const ChartContainer: FC<ChartContainerProps & { children: React.ReactNode }> = ({
    title,
    unit,
    size = '1',
    children,
}) => {
    return (
        <Grid item {...BREAKPOINTS[size]}>
            <Paper sx={(theme) => ({ p: 4, borderRadius: theme.shape.borderRadius / 2 })}>
                <Typography variant="h6" mb={2}>
                    {title}
                    {unit && (
                        <Typography fontWeight="inherit" component="span" color="text.secondary">
                            &nbsp;&#91;{unit}&#93;
                        </Typography>
                    )}
                </Typography>
                <ErrorBoundary fallback={<Typography>Error rendering component</Typography>}>{children}</ErrorBoundary>
            </Paper>
        </Grid>
    )
}

export default ChartContainer
