import { FC, ReactNode } from 'react'
import { Grid, Typography } from '@mui/material'

interface DashboardSectionProps {
    title?: ReactNode
    children: ReactNode
    hidden?: boolean
}

const DashboardSection: FC<DashboardSectionProps> = ({ title, children, hidden = false }) => {
    if (hidden) return null

    return (
        <>
            {title && (
                <Typography variant="h5" gutterBottom mb={2}>
                    {title}
                </Typography>
            )}
            <Grid container spacing={2} sx={{ mb: 4 }}>
                {children}
            </Grid>
        </>
    )
}
export default DashboardSection
