import { get } from './utils.service'
import { Meter, Measurement, MeasurementAggregation, MeasurementGroupBy, Feed } from '@/types/meters'

export const getMeters = async (siteHash: string) => {
    const res = await get(`/sites/${siteHash}/meters`)
    const meters = await res.json()
    return meters as Meter[]
}

const buildQueryString = (params: Record<string, string | number | string[] | number[] | undefined>): string => {
    const queryParams = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
        if (value) {
            if (Array.isArray(value)) {
                value.forEach((v) => queryParams.append(`${key}[]`, String(v)))
            } else {
                queryParams.append(key, String(value))
            }
        }
    })
    return queryParams.toString()
}

export const getSeriesMeasurements = async (
    siteHash: string,
    meterId: string,
    seriesId: string,
    options: { from: string; to: string; groupBy?: MeasurementGroupBy; aggBy?: MeasurementAggregation[] }
): Promise<Measurement[]> => {
    const queryString = buildQueryString(options)
    const path = `/sites/${siteHash}/meters/${meterId}/series/${seriesId}/measurements?${queryString}`
    const res = await get(path)
    const measurements = await res.json()
    // console.log('measurements', measurements)
    return measurements as Measurement[]
}

export const getSiteFeeds = async (siteHash: string): Promise<Feed[]> => {
    const res = await get(`/sites/${siteHash}/feeds`)
    const feeds = await res.json()
    return feeds as Feed[]
}
