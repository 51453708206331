import { FC } from 'react'
import { FeedStatus, MeterType } from '@/types/meters'
import { Chip, ChipProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import CloudIcon from '@mui/icons-material/Cloud'
import GroupIcon from '@mui/icons-material/Group'
import HeatPumpIcon from '@mui/icons-material/HeatPump'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

export const FeedStatusChip: FC<{ status: FeedStatus }> = ({ status }) => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.feeds' })

    const colors: Record<FeedStatus, ChipProps['color']> = {
        [FeedStatus.OK]: 'success',
        [FeedStatus.PENDING]: 'warning',
        [FeedStatus.PROVIDER_ERROR]: 'error',
        [FeedStatus.INTERNAL_ERROR]: 'error',
    }
    const icons: Record<FeedStatus, JSX.Element> = {
        [FeedStatus.OK]: <CheckIcon fontSize="inherit" />,
        [FeedStatus.PENDING]: <AccessTimeIcon fontSize="inherit" />,
        [FeedStatus.PROVIDER_ERROR]: <ErrorOutlineIcon fontSize="inherit" />,
        [FeedStatus.INTERNAL_ERROR]: <ErrorOutlineIcon fontSize="inherit" />,
    }
    return (
        <Chip
            label={t(`statuses.${status}`, { defaultValue: status })}
            color={colors[status]}
            size="small"
            sx={{ fontWeight: 'medium' }}
            icon={icons[status]}
            variant="outlined"
        />
    )
}

export const FeedTypeChip: FC<{ type: MeterType }> = ({ type }) => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.feeds' })
    const icons: Record<MeterType, JSX.Element> = {
        [MeterType.ENERGY]: <ElectricBoltIcon fontSize="inherit" />,
        [MeterType.METEO]: <CloudIcon fontSize="inherit" />,
        [MeterType.OCCUPANCY]: <GroupIcon fontSize="inherit" />,
        [MeterType.AGRID_BMS]: <HeatPumpIcon fontSize="inherit" />,
    }

    return (
        <Chip
            label={t(`types.${type}`, { defaultValue: type })}
            size="small"
            color="primary"
            sx={{ fontWeight: 'medium', px: 0.5 }}
            icon={icons[type]}
            variant="outlined"
        />
    )
}
