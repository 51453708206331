import { VFC } from 'react'
import { Box, BoxProps, Skeleton } from '@mui/material'

interface LoadingSkeletonsProps extends BoxProps {
    count: number
}

export const LoadingSkeletons: VFC<LoadingSkeletonsProps> = ({ count, ...boxProps }) => (
    <Box {...boxProps}>
        {[...Array(count)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" height={100} sx={{ my: 2 }} />
        ))}
    </Box>
)

export default LoadingSkeletons
