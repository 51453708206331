import Highcharts from 'highcharts'
import 'highcharts/highcharts-more'

type HighchartsInstance = typeof Highcharts

interface UseHighchartsReturn {
    highchartsInstance: HighchartsInstance
    getColor: (index: number) => string | Highcharts.GradientColorObject | Highcharts.PatternObject | undefined
}

const useHighcharts = (): UseHighchartsReturn => {
    const highchartsInstance = Highcharts

    // Set the translations
    highchartsInstance.setOptions({
        lang: {
            resetZoomTitle: 'Réinitialiser le zoom',
            resetZoom: 'Réinitialiser',
            months: [
                'Janvier',
                'Février',
                'Mars',
                'Avril',
                'Mai',
                'Juin',
                'Juillet',
                'Août',
                'Septembre',
                'Octobre',
                'Novembre',
                'Décembre',
            ],
            weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
            rangeSelectorFrom: 'Du',
            rangeSelectorTo: 'Au',
            rangeSelectorZoom: 'Période',
        },
    })

    const getColor = (index: number) =>
        Highcharts.getOptions().colors?.[index % (Highcharts.getOptions().colors?.length || 1)]

    return { highchartsInstance, getColor }
}

export default useHighcharts
