import { MeasurementsQueryResult } from '../../timeSeries/types'
import sumbBy from 'lodash/sumBy'
import meanBy from 'lodash/meanBy'

export const formatPercent = (n: number | undefined, decimals = 2) =>
    n ? `${new Intl.NumberFormat('fr-FR', { maximumFractionDigits: decimals }).format(n * 100)}` : '-'

export const formatValue = (n: number | undefined, decimals = 2) =>
    n ? `${new Intl.NumberFormat('fr-FR', { maximumFractionDigits: decimals }).format(n)}` : '-'

export const getIsLoading = (series: MeasurementsQueryResult[]) => {
    return series.some((s) => s.isLoading)
}

export const getUnit = (series: MeasurementsQueryResult[]): string => {
    const firstSeries = series[0]
    return firstSeries?.data.unit || ''
}

export const getValue = (series: MeasurementsQueryResult[], aggregateFn: 'mean' | 'sum'): number | undefined => {
    const isLoading = getIsLoading(series)
    if (isLoading || !series.length) {
        return undefined
    }
    const allMeasurements = series.flatMap((s) => s.data.measurements)
    const aggregator = aggregateFn === 'mean' ? meanBy : sumbBy
    return aggregator(allMeasurements, 'value')
}
