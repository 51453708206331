import { FC } from 'react'
import { useTimeSeriesMeasurements } from '../../timeSeries/useTimeSeriesMeasurements'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MeterType } from '@/types/meters'
import { LoadingSkeleton } from './LoadingSkeleton'
import { formatPercent, formatValue, getValue, getIsLoading, getUnit } from './seriesUtils'

const HVACSummary: FC = () => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard' })
    const { series: avgStateSeries } = useTimeSeriesMeasurements({ type: MeterType.AGRID_BMS, series: ['state'] })
    const { series: avgTSetpointSeries } = useTimeSeriesMeasurements({
        type: MeterType.AGRID_BMS,
        series: ['temperature_setpoint'],
    })
    const { series: avgTemperatureSeries } = useTimeSeriesMeasurements({
        type: MeterType.AGRID_BMS,
        series: ['temperature_measured'],
    })

    const avgMetrics = [
        {
            key: 'avgState',
            series: avgStateSeries,
            formatter: formatPercent,
            unit: '%',
        },
        {
            key: 'avgSetpoint',
            series: avgTSetpointSeries,
            formatter: formatValue,
        },
        {
            key: 'avgTemperature',
            series: avgTemperatureSeries,
            formatter: formatValue,
        },
    ]

    return (
        <Table>
            <TableBody>
                {avgMetrics.map(({ key, series, formatter, unit: providedUnit }) => {
                    const unit = providedUnit || getUnit(series)
                    const formattedValue = formatter(getValue(series, 'mean'))
                    return (
                        <TableRow key={key}>
                            <TableCell>{t(`main.hvac.${key}`, { defaultValue: key })}</TableCell>
                            <TableCell>
                                {getIsLoading(series) ? (
                                    <LoadingSkeleton sx={{ mr: 6 }} />
                                ) : (
                                    <span>
                                        {formattedValue}&nbsp;{unit}
                                    </span>
                                )}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}

export default HVACSummary
