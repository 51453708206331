import { Measurement } from '@/types/meters'

export const getSeriesTimeSpanDays = (measurements: Measurement[]): number => {
    if (measurements.length < 2) {
        return 0
    }

    const first = new Date(measurements[0].timestamp).getTime()
    const last = new Date(measurements[measurements.length - 1].timestamp).getTime()

    return Math.round((last - first) / (1000 * 60 * 60 * 24))
}
