import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const ChartErrorFallback: FC = () => {
    const { t } = useTranslation('site')
    return (
        <Box sx={{ textAlign: 'center', mt: 4, color: 'text.secondary' }}>
            <ErrorOutlineIcon sx={{ fontSize: 32 }} />
            <Typography variant="body1">{t('dashboard.fallBacks.error')}</Typography>
        </Box>
    )
}

export default ChartErrorFallback
