/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import { FC, useState } from 'react'
import { MeterType } from '@/types/meters'
import TimeSeriesChart from '../../components/timeSeries/TimeSeriesChart'
import DashboardSection from '../DashboardSection'
import { useHVACDashboardData } from './useHVACDashboardData'
import { TreeComponent } from './tree.component'
import ChartContainer from '../../components/timeSeries/ChartContainer'
import { ScatterComponent } from '../../components/scatter.component'
import min from 'lodash/min'
import max from 'lodash/max'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import camelCase from 'lodash/camelCase'

const HVACDashboard: FC = () => {
    const { isLoading, treeMapData, siteHash, scatterData, navigateToObject } = useHVACDashboardData()
    const [scatterFilterDelta, setScatterFilterDelta] = useState(2)
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.hvac' })
    const seriesLabelGetter = (series: any) => t(camelCase(series.name), { defaultValue: series.name })

    return (
        <>
            <DashboardSection title={t('systemsUsage')}>
                <TimeSeriesChart
                    filters={{
                        type: MeterType.AGRID_BMS,
                        series: ['temperature_setpoint', 'temperature_measured'],
                    }}
                    title={t('temperatures')}
                    unit="auto"
                    type="line"
                    options={{
                        labelGetter: seriesLabelGetter,
                    }}
                    aggregateOptions={{
                        aggBy: ['avg'],
                        groupBy: 'day',
                    }}
                    size="1/2"
                />
                <TimeSeriesChart
                    filters={{
                        type: MeterType.AGRID_BMS,
                        series: ['state'],
                    }}
                    title={t('state')}
                    type="line"
                    unit="%"
                    options={{ labelGetter: seriesLabelGetter }}
                    aggregateOptions={{
                        aggBy: ['avg'],
                        groupBy: 'day',
                        unit: '%',
                    }}
                    size="1/2"
                />
            </DashboardSection>
            <DashboardSection title={t('dailyProfiles')}>
                <TimeSeriesChart
                    filters={{
                        type: MeterType.AGRID_BMS,
                        series: ['temperature_setpoint', 'temperature_measured'],
                    }}
                    title={t('temperatures')}
                    unit="auto"
                    type="line"
                    options={{ labelGetter: seriesLabelGetter }}
                    aggregateOptions={{
                        aggBy: ['avg'],
                        groupBy: 'time',
                    }}
                    size="1/2"
                />
                <TimeSeriesChart
                    filters={{
                        type: MeterType.AGRID_BMS,
                        series: ['state'],
                    }}
                    title={t('state')}
                    type="line"
                    options={{ labelGetter: seriesLabelGetter }}
                    unit="%"
                    aggregateOptions={{
                        aggBy: ['avg'],
                        groupBy: 'time',
                        unit: '%',
                    }}
                    size="1/2"
                />
            </DashboardSection>
            <DashboardSection title={t('pilotActions')}>
                <TimeSeriesChart
                    filters={{
                        type: MeterType.AGRID_BMS,
                        series: ['actions_count_temperature_setpoint'],
                    }}
                    title={t('setpointActions')}
                    type="bar"
                    options={{ labelGetter: seriesLabelGetter }}
                    size="1/2"
                />
                <TimeSeriesChart
                    filters={{
                        type: MeterType.AGRID_BMS,
                        series: ['actions_count_state_on', 'actions_count_state_off'],
                    }}
                    title={t('stateActions')}
                    type="bar"
                    options={{ labelGetter: (series) => t(camelCase(series.name), { defaultValue: series.name }) }}
                    size="1/2"
                />
            </DashboardSection>
            <DashboardSection title={t('detailsPerZone')}>
                {(isLoading || treeMapData) && (
                    <ChartContainer title={t('consumptionRepartition')} size="1">
                        {isLoading ? (
                            <Skeleton variant="rectangular" height={400} />
                        ) : (
                            <TreeComponent data={treeMapData} siteHash={siteHash} setSelectedZone={navigateToObject} />
                        )}
                    </ChartContainer>
                )}
                {(isLoading || scatterData) && (
                    <ChartContainer title={t('resilience')} size="1">
                        {isLoading || !scatterData ? (
                            <Skeleton variant="rectangular" height={400} />
                        ) : (
                            <ScatterComponent
                                scatterData={scatterData}
                                series={[
                                    {
                                        name: 'Espaces',
                                        color: 'rgba(119, 152, 191, .5)',
                                        data: scatterData,
                                        dataLabels: {
                                            enabled: true,
                                            formatter: function (this: any): string {
                                                return this.point.name
                                            },
                                        },
                                    },
                                    {
                                        type: 'line',
                                        color: 'black',
                                        dashStyle: 'dot',
                                        marker: {
                                            symbol: 'circle', // Change to 'circle', 'square', or 'triangle'
                                        },
                                        width: 2,
                                        data: [
                                            {
                                                x:
                                                    (min(
                                                        scatterData.map((el): number => Math.min(el.x, el.y))
                                                    ) as number) - 0.5,
                                                y: (min(scatterData.map((el) => Math.min(el.x, el.y))) as number) - 0.5,
                                            },
                                            {
                                                x: (max(scatterData.map((el) => Math.max(el.x, el.y))) as number) + 0.5,
                                                y: (max(scatterData.map((el) => Math.max(el.x, el.y))) as number) + 0.5,
                                            },
                                        ],
                                    },
                                    {
                                        type: 'line',
                                        color: 'lightgrey',
                                        dashStyle: 'dash',
                                        marker: {
                                            symbol: 'circle', // Change to 'circle', 'square', or 'triangle'
                                        },
                                        lineWidth: 1,
                                        data: [
                                            {
                                                x: Math.min(...scatterData?.map((el) => Math.min(el.x, el.y))) - 0.5,
                                                y:
                                                    Math.min(...scatterData?.map((el) => Math.min(el.x, el.y))) -
                                                    0.5 +
                                                    scatterFilterDelta,
                                            },
                                            {
                                                x: Math.max(...scatterData?.map((el) => Math.max(el.x, el.y))) + 0.5,
                                                y:
                                                    Math.max(...scatterData?.map((el) => Math.max(el.x, el.y))) +
                                                    0.5 +
                                                    scatterFilterDelta,
                                            },
                                        ],
                                    },
                                    {
                                        type: 'line',
                                        color: 'lightgrey',
                                        dashStyle: 'dash',
                                        marker: {
                                            symbol: 'circle', // Change to 'circle', 'square', or 'triangle'
                                        },
                                        lineWidth: 1,
                                        data: [
                                            {
                                                x: Math.min(...scatterData?.map((el) => Math.min(el.x, el.y))) - 0.5,
                                                y:
                                                    Math.min(...scatterData?.map((el) => Math.min(el.x, el.y))) -
                                                    0.5 -
                                                    scatterFilterDelta,
                                            },
                                            {
                                                x: Math.max(...scatterData?.map((el) => Math.max(el.x, el.y))) + 0.5,
                                                y:
                                                    Math.max(...scatterData?.map((el) => Math.max(el.x, el.y))) +
                                                    0.5 -
                                                    scatterFilterDelta,
                                            },
                                        ],
                                    },
                                ]}
                                setSelectedZone={navigateToObject}
                                scatterFilterDelta={scatterFilterDelta}
                                setScatterFilterDelta={setScatterFilterDelta}
                            />
                        )}
                    </ChartContainer>
                )}
            </DashboardSection>
        </>
    )
}

export default HVACDashboard
