import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { isDemo } from '../services/utils.service'

export const verifyUser = ({ Email, LastName, FirstName }) => {
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(Email) && LastName && FirstName
}

export const formatNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const roundHalf = (num) => {
    return Math.round(num * 2) / 2
}

export const formatNumberByType = (type, x) => {
    if (type.includes('TEMPERATURE')) return x.toFixed(1)
    else if (type === 'STATE') return parseInt(x)
    else if (typeof x === 'number') return x.toFixed(2)
    else return x
}

export const colorTypes = {
    ThermalZone: '#2DCAD5',
    Building: '#53D186',
    Floor: '#4285F4',
}

export const createMomentDate = (date) => {
    let newDate = new Date(date.year, parseInt(date.month) - 1, date.day)
    return moment(newDate)
}

export const extractDateFromMomentDate = (momentDate) => {
    let newDate = moment(momentDate).format('YYYY-MM-DD')
    return {
        day: newDate.split('-')[2],
        month: parseInt(newDate.split('-')[1]),
        year: newDate.split('-')[0],
    }
}

export const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    }
}

export const extractList = (list) => {
    //List: "0|Cool;1|Heat;2|Dry;3|Fan;4|Auto"
    let output = {}
    let array = list.split(';')
    for (let i = 0; i < array.length; i++) {
        let item = array[i].split('|')
        if (item.length === 2) {
            output[item[0]] = item[1]
        }
    }
    return output
}

export const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const getCookie = (name) => {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(';')

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split('=')

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1])
        }
    }

    // Return null if not found
    return null
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

export const makeid = () => {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < 20; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

export const hashCode = (string) => {
    var hash = 0,
        i,
        chr
    if (string.length === 0) return hash
    for (i = 0; i < string.length; i++) {
        chr = string.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
    }
    return hash
}

export const uniq = (a, key) => {
    var seen = {}
    return a?.filter(function (item) {
        // eslint-disable-next-line no-prototype-builtins
        return seen.hasOwnProperty(item[key]) ? false : (seen[item[key]] = true)
    })
}

export const compareDates = (date1, date2) => {
    let d1 = new Date(date1)
    let d2 = new Date(date2)
    return d1.getTime() > d2.getTime()
}

export const removeLocalStorage = () => {
    for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i)
        if (key.endsWith('_realtime') || key.endsWith('_translation')) {
            localStorage.removeItem(key)
        }
    }
}

export const checkPermission = (currentView, me) => {
    let permissions = me?.userPermissions
    let is_allowed = permissions?.find((el) => el?.Section === currentView)
    if (!is_allowed && !isDemo()) {
        return false
    }
    return true
}

export const getRedirection = (me) => {
    let permissions = me?.userPermissions
    let redirect_realtime = permissions?.find((el) => el?.Section === 'RealtimeView')
    if (redirect_realtime) {
        return '/realtime'
    } else {
        let redirect_documents = permissions?.find((el) => el?.Section === 'DocumentsView')
        if (redirect_documents) {
            return '/documents'
        } else {
            let redirect_dashboard = permissions?.find((el) => el?.Section === 'DashboardGroupView')
            if (redirect_dashboard) {
                return '/dashboard'
            }
        }
    }
    return
}

export const uniqueArray = (array) =>
    Array.from(new Set(array.map((item) => item.ObjectId))).map((objectId) => {
        return array.find((item) => item.ObjectId === objectId)
    })
